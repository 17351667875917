import React, { Component } from 'react';
import i18n from "i18next";
import { withNamespaces  } from 'react-i18next';

class Facility extends Component {
    componentWillMount() {
        i18n.addResourceBundle('vn', 'icon-facikity1', {
            key: 'Năng lực sản xuất hàng năm 7.730 tấn',
        });
        i18n.addResourceBundle('en', 'icon-facikity1', {
            key: 'Annual production capacity: 7,730 tons',
        });

        i18n.addResourceBundle('vn', 'icon-facikity2', {
            key: 'Xây dựng hơn 50 cơ sở sản xuất',
        });
        i18n.addResourceBundle('en', 'icon-facikity2', {
            key: 'Production facilities by 50 types',
        });

        i18n.addResourceBundle('vn', 'icon-facikity3', {
            key: 'Hơn 20 bằng sáng chế và chứng chỉ',
        });
        i18n.addResourceBundle('en', 'icon-facikity3', {
            key: 'About 20 patents and certifications',
        });

        i18n.addResourceBundle('vn', 'icon-facikity4', {
            key: 'Xuất khẩu sang 16 quốc gia',
        });
        i18n.addResourceBundle('en', 'icon-facikity4', {
            key: 'Exports to 16 countries',
        });

        i18n.addResourceBundle('vn', 'title-facikity', {
            key: 'CƠ SỞ',
        });
        i18n.addResourceBundle('en', 'title-facikity', {
            key: 'FACILITY',
        });

        i18n.addResourceBundle('vn', 'title-facikity1', {
            key: '“Các sản phẩm sản xuất OEM/ODM và các dòng sản phẩm khác đáp ứng theo nhu cầu của khách hàng (doanh nghiệp/công ty đặt hàng)”',
        });
        i18n.addResourceBundle('en', 'title-facikity1', {
            key: '“Satisfy the needs of clients (ordering companies) with OEM/ODM products and various product types”',
        });

        i18n.addResourceBundle('vn', 'content-facikity', {
            key: 'Daedong Korea Ginseng Co., Ltd sản xuất các sản phẩm sâm nguyên củ, chiết xuất nhân sâm cô đặc, các sản phẩm dạng bột, hạt, viên nén, viên nang... Chúng tôi có nhiều dòng sản phẩm được phân loại theo công dụng và đặc điểm của sản phẩm.</p><p>&nbsp;</p><p><a href="/facility" class="more-info">Xem thêm: Cơ sở sản xuất</a>',
        });
        i18n.addResourceBundle('en', 'content-facikity', {
            key: 'Daedong Korea Ginseng Co., Ltd. is equipped with various facilities that follow the characteristics of each product, capacity and product type, and charging equipment for manufacturing capsules, tablets, fruit granules, powder of solid formulation, liquefied extractconcentrate, and root ginseng.</p><p>&nbsp;</p><p><a href="/facility" class="more-info">Detail view of production facilities</a>',
        });

    }
    render() {
        const currentHeight = (window.innerWidth >  767) ? window.innerHeight+'px' : 'auto';
        return (
            <div id="section-3" className="section row wapExport d-flex align-items-center" style={{height: currentHeight, backgroundColor: '#d7171f', margin: 0 }}>
                <div className="col-md-6 col-12 exportLeft">
                    <div className="page-description">
                        <div className="clearfix">
                            <div className="one_half">
                                <span className="feature-item-media counter" style={{ backgroundImage: "url(" + require('../../lib/img/facility-icon-01.png') + ")" }}>7,730</span><p></p>
                                <p className="feature-item-content">{i18n.t('icon-facikity1:key')}</p>
                            </div>
                            <div className="one_half last">
                                <span className="feature-item-media counter" style={{ backgroundImage: "url(" + require('../../lib/img/facility-icon-02.png') + ")" }}>50</span><p></p>
                                <p className="feature-item-content">{i18n.t('icon-facikity2:key')}</p>
                            </div>
                        </div>
                        <p>&nbsp;</p>
                        <div className="clearfix">
                            <div className="one_half">
                                <span className="feature-item-media counter" style={{ backgroundImage: "url(" + require('../../lib/img/facility-icon-03.png') + ")" }}>20</span><p></p>
                                <p className="feature-item-content">{i18n.t('icon-facikity3:key')}</p>
                            </div>
                            <div className="one_half last">
                                <span className="feature-item-media counter" style={{ backgroundImage: "url(" + require('../../lib/img/facility-icon-04.png') + ")" }}>16</span><p></p>
                                <p className="feature-item-content">{i18n.t('icon-facikity4:key')}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-12 exportRight d-flex align-items-center">
                    <div className="page-description text-justify">
                        <h3 className="section-title">{i18n.t('title-facikity:key')}</h3>
                        <h3 className="title">{i18n.t('title-facikity1:key')}</h3>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('content-facikity:key')}}></p>
                    </div>
                </div>
            </div>
        );
    }
}

export default withNamespaces()(Facility);