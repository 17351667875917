import React, { Component } from 'react';
import Navigation from '../header/navigation';
import Contact from '../home-page/Contact';
import Footer from '../footer/Footer';
import $ from 'jquery';

class Vision extends Component {
    componentDidMount(){
        document.body.classList.add("isVision");
    }
    render() {
        $('body').removeAttr( "class" );
        return (
            <div className="full-screen-scroller">
                <Navigation />
                <div id="breadcrumbs" style={{ backgroundImage: "url(" + require('../../lib/img/bgtop-company-5.jpg') + ")" }}></div>
                <div id="breadcrumbs_box">
                    <div className="container clearfix text-right">
                        <i className="fas fa-home"></i> <a href="/">Trang chủ</a> <i className="fas fa-angle-double-right" style={{ fontSize: "10px" }}></i> Tầm nhìn
                    </div>
                </div>
                <div className="container" style={{paddingTop: "40px"}}>
                    <h1 className="title-page">TẦM NHÌN</h1>                    
                    <h5 className="sub-title-page"><i className="fas fa-quote-left"></i>Daedong Korea Ginseng là một trong số ít những tập đoàn sản xuất và chế biến Nhân sâm (Ginseng Road)  được Chính Phủ Hàn Quốc khen thưởng và bảo trợ.</h5>
                    <img src={require('../../lib/img/vision-01.jpg')} className="img-fluid mx-auto d-block" alt="Vision Daedong"/>
                    <div className="row" style={{paddingTop: "40px"}}>
                        <div className="col-12 col-sm-7">
                            <h2 className="h2-title-page">| TRIẾT LÝ DOANH NGHIỆP</h2>
                            <p className="text-justify">
                                Sản xuất các dòng sản phẩm CHẤT LƯỢNG VƯỢT TRỘI khi luôn giữ tâm suy nghĩ tích cực và không ngại thách thức bản thân với những sáng tạo mới.
                            </p>
                            <h2 className="h2-title-page">| SỨ MỆNH DOANH NGHIỆP</h2>
                            <h5>TRUNG THỰC(正直):</h5>
                            <p className="text-justify">
                                Daedong giữ tâm thế trung thực, tân tâm từ khâu lựa chọn nguyên liệu thô tốt nhất, không trộn lẫn, kết hợp tạp chất, nguyên liệu kém chất lượng, đảm bảo sản phẩm vượt trội nhất khi được ra mắt.
                            </p>
                            <h5>CHÍNH XÁC (正確):</h5>
                            <p className="text-justify">
                                Mọi quy trình sản xuất đều đảm bảo nguyên tắc, với công nghệ chính xác để sản xuất các sản phẩm đạt chuẩn, đáng tin cậy trên thị trường.
                            </p>
                            <h5>CHÂN THÀNH (精誠):</h5>
                            <p className="text-justify">
                            Thấu hiểu được mong muốn và suy nghĩa của người tiêu dùng, Daedong chỉ cung cấp những dòng sản phẩm tốt nhất, phù hợp nhất tới khách hàng với thái độ cầu thị, chân thành, siêng năng.
                            </p>
                        </div>
                        <div className="col-12 col-sm-5">
                            <img src={require('../../lib/img/vision-02.png')} className="img-fluid mx-auto d-block" alt="Vision Daedong"/>
                        </div>
                    </div>
                    <img src={require('../../lib/img/vision-03.jpg')} className="img-fluid mx-auto d-block" alt="Vision Daedong" style={{marginTop:"40px"}}/>
                </div>

                <Contact/>

                <Footer/>

            </div>
        );
    }
}

export default Vision;