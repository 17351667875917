import React, { Component } from 'react';
import Navigation from '../header/navigation';
import Contact from '../home-page/Contact';
import Footer from '../footer/Footer';
import $ from 'jquery';

class FacilityDetail extends Component {
    componentWillMount(){
        document.body.classList.remove("isFacilityDetail");       
    }
    render() {
        $('body').removeAttr( "class" );
        return (
            <div className="full-screen-scroller">
                <Navigation/>
                <div id="breadcrumbs" style={{backgroundImage: "url(" + require('../../lib/img/bgtop-business-2.jpg') + ")"}}></div>
                <div id="breadcrumbs_box">
                    <div className="container clearfix text-right">
                        <i className="fas fa-home"></i> <a href="/">Trang chủ</a> <i className="fas fa-angle-double-right" style={{fontSize:"10px"}}></i> Cơ sở sản xuất
                    </div>
                </div>
                <div className="container" style={{paddingTop: "40px"}}>
                    <h1 className="title-page">Cơ sở sản xuất</h1>                    
                    <h5 className="sub-title-page"><i className="fas fa-quote-left"></i>Daedong Korea Ginseng Co., Ltd mỗi năm sản xuất lên tới 7730 tấn với đa dạng nhiều dòng sản phẩm khác nhau.</h5>
                    <img src={require('../../lib/img/facility-01.jpg')} className="img-fluid mx-auto d-block" alt="Facility Ginssen"/>
                    <div className="row" style={{paddingTop: "40px"}}>
                        <div className="col-12 col-sm-4">
                            <h2 className="h2-title-page text-justify">Nhân sâm truyền thống (Sâm nguyên củ) – 본삼(뿌리삼)</h2>
                            <p className="text-justify">
                                Máy rửa/làm sạch, Máy hấp chuyên dụng, Máy sấy, Máy sấy đá công nghệ độc quyền Elven, Máy sấy không khí lạnh, Máy giữ ẩm, Máy đóng gói sản phẩm.
                            </p>
                        </div>
                        <div className="col-12 col-sm-8" style={{borderLeft: "1px solid #ddd", paddingLeft: "20px"}}>
                            <h5>| Sâm thái lát |</h5>
                            <p className="text-justify">
                                Máy rửa/làm sạch, máy sấy, máy cắt kim loại chuyên dụng, máy dập, May đo tự động khối lượng, máy đóng gói hút chân không, máy niêm phong.
                            </p>
                            <h5>| Sâm dạng cao cô đặc |</h5>
                            <p className="text-justify">
                                Máy trích xuất, máy ly tâm, máy bay hơi liên tục, khuấy khuếch tán, bộ sạc tự động, máy ghi nhãn tự động, bể lên men.
                            </p>
                            <h5>| Sâm dạng túi |</h5>
                            <p className="text-justify">
                                Máy lên men, máy trộn, tiệt trùng, chất làm đầy chất lỏng quay, máy kiểm tra cân, bộ sạc dính nhớt độ nhớt cao.
                            </p>
                            <h5>| Sâm dạng viên nang |</h5>
                            <p className="text-justify">
                                Máy trộn, máy tạo viên, máy phân loại, máy đóng gói PTP, chai đựng tiệt trùng.
                            </p>                            
                            <h5>| Sâm dạng viên tablet |</h5>
                            <p className="text-justify">
                                Máy trộn, máy tạo viên, máy phân loại, máy đóng gói PTP, chai đựng tiệt trùng.
                            </p>
                            <h5>| Sâm dạng viên hoàn |</h5>
                            <p className="text-justify">
                                Máy trộn (máy tốc độ cao), máy ép công nghệ cao, máy sấy khô, máy đóng gói PE.
                            </p>
                            <h5>| Sâm dạng bột |</h5>
                            <p className="text-justify">
                                Máy nghiền bột, máy sấy, máy đóng gói, máy đóng gói PE
                            </p>
                            <h5>| Sâm dạng hạt |</h5>
                            <p className="text-justify">
                                Máy trộn hỗn hợp, Máy tạo hạt, máy sấy chất lưu, Máy phân loại, Máy đóng gọi dốc, thanh đóng gói máy.
                            </p>
                        </div>
                    </div>
                    <div className="row" style={{paddingTop: "40px"}}>
                        <div className="col-12 col-sm-4">
                            <h2 className="h2-title-page text-justify">NĂNG LỰC SẢN XUẤT HÀNG NĂM</h2>
                            <h5 className="sub-title-page"><i className="fas fa-quote-left"></i> Daedong Korea Ginseng Co Ltd sản xuất 7730 tấn mỗi năm</h5>
                        </div>
                        <div className="col-12 col-sm-8" style={{border: "1px solid #ddd"}}>
                            <img src={require('../../lib/img/facility-02.jpg')} className="img-fluid mx-auto d-block" alt="Facility Ginssen"/>
                        </div>
                    </div>
                    <div className="row" style={{paddingTop: "40px"}}>
                        <div className="col-12 col-sm-4">
                            <h2 className="h2-title-page text-justify">Cơ sở vật chất chủ yếu</h2>
                            <p className="text-justify">
                                Daedong Korea Ginseng Co Ltd có nhiều cơ sở lớn và sản xuất các sản phẩm tốt nhất thông qua hệ thống quản lý chặt chẽ.
                            </p>
                        </div>
                        <div className="col-12 col-sm-8" style={{borderLeft: "1px solid #ddd", paddingLeft: "20px"}}>
                            <h5>| Thiết bị GMP |</h5>
                            <p className="text-justify">Hệ thống đóng gói tự động: đóng gói dạng kẹp, đóng gói dạng hạt chuyên nghiệp.</p>
                            <p className="text-justify">Máy sấy chất lỏng, máy làm khô, máy làm nguội, máy trộn bột (Granulator), máy trộn tốc độ cao</p>
                            <p className="text-justify">Máy tách oscillator / thiết bị phân tách, máy sấy khô, máy sấy phun</p>
                            <p className="text-justify">Máy lên men cường độ cao Liquid Stick Charger</p>
                            <h5>| Thiết bị GAP |</h5>
                            <p className="text-justify">
                                Máy sấy lạnh, máy mài, máy sấy nóng không khí, dạng lỏng /bể chiết xuất nồng độ/ bộ tách tự động điện tử.
                            </p>                            
                            <p className="text-justify">
                            Máy sấy nóng, máy sấy sâm tươi, máy rửa / Máy đóng gọi 3 pack / máy hút chân không, máy làm ẩm.
                            </p>                            
                        </div>
                    </div>                    
                </div>
               
                <Contact/>

                <Footer/>
            </div>
        );
    }
}

export default FacilityDetail;