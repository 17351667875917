import React, { Component } from 'react';
import i18n from "i18next";
import { withNamespaces  } from 'react-i18next';

class Technology extends Component {
    componentWillMount() {
        i18n.addResourceBundle('vn', 'title', {
            key: 'CÔNG NGHỆ CỦA CHÚNG TÔI',
        });
        i18n.addResourceBundle('vn', 'sub1', {
            key: 'Nguyên liệu tốt nhất • Bí quyết quản lý sản xuất chuyên nghiệp • Công nghệ tiên tiến hiện đại độc quyền ',
        });        
        i18n.addResourceBundle('en', 'title', {
            key: 'OUR TECHNOLOGY',
        });
        i18n.addResourceBundle('en', 'sub1', {
            key: 'The best raw materials • Rich know-how • Advanced technology',
        });
        i18n.addResourceBundle('vn', 'sub2', {
            key: 'Quản lý thời gian thông minh – Vệ sinh an toàn thực phẩm chuyên nghiệp<br/>tạo nên sự khác biệt cho sản phẩm của Daedong.',
        });
        i18n.addResourceBundle('en', 'sub2', {
            key: 'Efficient time management and cleanliness make a difference in quality',
        });
        i18n.addResourceBundle('vn', 'icon1', {
            key: 'Hệ thống sấy khô hiện đại',
        });
        i18n.addResourceBundle('en', 'icon1', {
            key: 'Elvan drying system',
        });
        i18n.addResourceBundle('vn', 'icon2', {
            key: 'Công nghệ sản xuất Fusion',
        });
        i18n.addResourceBundle('en', 'icon2', {
            key: 'New convergence concentration system',
        });
        i18n.addResourceBundle('vn', 'icon3', {
            key: 'Hệ thống làm sạch cao cấp',
        });
        i18n.addResourceBundle('en', 'icon3', {
            key: 'Clean process system',
        });
        i18n.addResourceBundle('vn', 'sub3', {
            key: 'Daedong Korea Ginseng Co.,Ltd là một trong những doanh nghiệp đi đầu trong sản xuất các dòng sản phẩm hắc sâm - hồng sâm cao cấp với nguồn nguyên liệu tốt nhất và công nghệ tiên tiến hiện đại, hướng tới mục tiêu phấn đấu và phục vụ sức khỏe toàn diện cho cộng đồng.',
        });
        i18n.addResourceBundle('en', 'sub3', {
            key: 'Based on the best reliable raw materials and advanced technology and through popularization of premium black ginseng and red ginseng products, Daedong Korea ginseng Co., Ltd. endeavors to improve human health.',
        });
    }
    render() {
        const currentHeight = (window.innerWidth >  767) ? window.innerHeight+'px' : 'auto';
        return (
            <div id="section-2" className="section container marketing d-flex justify-content-center align-items-center" style={{height: currentHeight}}>
                {/* <!--Begin Technology--> */}

                {/* <!-- /END THE FEATURETTES --> */}
                <div className="row technology">
                    <div className="col-lg-12 col-12 text-center">
                        <h3 className="tit-brand">{i18n.t('title:key')}</h3>
                        <p className="section-subtitle">{i18n.t('sub1:key')}</p>
                        <p className="plus">+</p>
                        <p dangerouslySetInnerHTML={{__html: i18n.t('sub2:key')}}/>
                    </div>
                    <div className="col-lg-4 col-12">
                        <a href="/elvan-ginssen"><img src={require('../../lib/img/service-icon-01.gif')} alt="Elvan drying system" className="mx-auto d-block" /></a>
                        <h4>| {i18n.t('icon1:key')} |</h4>
                    </div>
                    <div className="col-lg-4 col-12">
                        <a href="/fusion-ginssen"><img src={require('../../lib/img/service-icon-02.gif')} alt="New convergence concentration system" className="mx-auto d-block" /></a>
                        <h4>| {i18n.t('icon2:key')} |</h4>
                    </div>
                    <div className="col-lg-4 col-12">
                        <a href="/clean-ginssen"><img src={require('../../lib/img/service-icon-03.gif')} alt="Clean process system" className="mx-auto d-block" /></a>
                        <h4>| {i18n.t('icon3:key')} |</h4>
                    </div>
                    <p className="col-lg-12 col-12 text-center">{i18n.t('sub3:key')}</p>
                </div>
                {/* <!--End Technology--> */}
            </div>
        );
    }
}

export default withNamespaces()(Technology);