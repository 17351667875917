import React, { Component } from 'react';
import Navigation from './header/navigation';
import Contact from './home-page/Contact';
import Footer from './footer/Footer';
import $ from 'jquery';

class Stores extends Component {
    componentDidMount(){
        document.body.classList.add("isStores");
    }  
    render() {
        $('body').removeAttr( "class" );
        return (
            <div className="full-screen-scroller container-fulid" style={{paddingTop:"100px"}}>
                <div className="fss-mainview fss-active">
                    <Navigation/>                    
                </div>

                <div className="container" style={{marginTop:'100px'}} id="accordion" style={{borderBottom:'none'}}>
                    <h2 className="mb-3 text-center mainTitlePage" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <i className="fas fa-store"></i> Hệ thống đại lý - Hàn Quốc
                    </h2>
                    <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                        <h4 className='titlePage'><i className='fas fa-fw fa-leaf'></i> Hệ thống cửa hàng Home Plus của Daedong KOREA CO., LTD.</h4>
                        <div className="row stores-list justify-content-around">                        
                            <div className="col-6 col-sm-4">
                                <div className="card">
                                    <img className='img-fluid' src={require('../lib/img/store1.jpg')} alt='Test Image Store'/>
                                    <div className="card-body">                               
                                        <h5 className="card-title">Home Plus Gimpo Branch</h5>
                                        <p className="card-text"><i className="fas fa-map-marker-alt"></i> B1, 14, Jungbong-ro, Gimpo-si, Gyeonggi-do Daedong Korea Ginseng Health Food Store</p>                                
                                        <button className="btn btn-outline-danger">Chi tiết <i className="fas fa-chevron-right"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-4">
                                <div className="card">
                                    <img className='img-fluid' src={require('../lib/img/store2.jpg')} alt='Test Image Store'/>
                                    <div className="card-body">                                
                                        <h5 className="card-title">Home Plus Bucheon Sosa Branch</h5>
                                        <p className="card-text"><i className="fas fa-map-marker-alt"></i> 532, Gyeonginro, Sosak-gu, Bucheon, Gyeonggi-do Daedong Korea Ginseng Health Food Store</p>
                                        <button className="btn btn-outline-danger">Chi tiết <i className="fas fa-chevron-right"></i></button>
                                    </div>
                                </div>
                            </div>                        
                            <div className="col-6 col-sm-4">
                                <div className="card">
                                    <img className='img-fluid' src={require('../lib/img/store3.jpg')} alt='Test Image Store'/>
                                    <div className="card-body">                                
                                        <h5 className="card-title">Home Plus Sejong Store</h5>
                                        <p className="card-text"><i className="fas fa-map-marker-alt"></i> Sejong Special Self-Governing Provincial Municipality 154 Basement 1F Daedong Korea Ginseng Health Food Store</p>
                                        <button className="btn btn-outline-danger">Chi tiết <i className="fas fa-chevron-right"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-4">
                                <div className="card">
                                    <img className='img-fluid' src={require('../lib/img/store4.jpg')} alt='Test Image Store'/>
                                    <div className="card-body">                               
                                        <h5 className="card-title">Homeplus large composition bookstore</h5>
                                        <p className="card-text"><i className="fas fa-map-marker-alt"></i> Daegu-dong, Dalseong-gu, Dalseo-gu 1467, 1 basement floor Daedong Korea Ginseng health food store</p>
                                        <button className="btn btn-outline-danger">Chi tiết <i className="fas fa-chevron-right"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-4">
                                <div className="card">
                                    <img className='img-fluid' src={require('../lib/img/store5.jpg')} alt='Test Image Store'/>
                                    <div className="card-body">                                                               
                                        <h5 className="card-title">Homeplus Changwon Store</h5>
                                        <p className="card-text"><i className="fas fa-map-marker-alt"></i> Changwon Road 415, Changwon City, Gyeongsangnam-do, 2F Daedong Korea Ginseng Health Food Store</p>
                                        <button className="btn btn-outline-danger">Chi tiết <i className="fas fa-chevron-right"></i></button>
                                    </div>
                                </div>
                            </div>                                                                                            
                        </div>
                        {/* Store2 */}
                        <h4 className='titlePage'><i className='fas fa-fw fa-leaf'></i> E-Mart Store của Daedong KOREA CO., LTD.</h4>
                        <div className="row stores-list justify-content-around">                        
                            <div className="col-6 col-sm-4">
                                <div className="card">
                                    <div className="card-body">                                                               
                                        <h5 className="card-title">E-Mart Eunpyeong</h5>
                                        <p className="card-text"><i className="fas fa-map-marker-alt"></i> Eunpyeong-gu, Eunpyeong-gu, Seoul, 2F Daedong Korea Ginseng Health Food Store</p>                                
                                        <button className="btn btn-outline-danger">Chi tiết <i className="fas fa-chevron-right"></i></button>
                                    </div>
                                </div>
                            </div>                                
                        </div>
                        {/* Store3 */}
                        <h4 className='titlePage'><i className='fas fa-fw fa-leaf'></i> Các doanh nghiệp vừa và nhỏ (Small and Medium Business) của Daedong KOREA CO., LTD.</h4>
                        <div className="row stores-list justify-content-around">                        
                            <div className="col-6 col-sm-4">
                                <div className="card">
                                    <img className='img-fluid' src={require('../lib/img/store6.jpg')} alt='Test Image Store'/>                              
                                    <div className="card-body">                                                               
                                        <h5 className="card-title">Small and Medium Business Seoul Station</h5>
                                        <p className="card-text"><i className="fas fa-map-marker-alt"></i> Seoul Station 43-205 Dongjak-dong, Yongsan-gu, Seoul</p>                                
                                        <button className="btn btn-outline-danger">Chi tiết <i className="fas fa-chevron-right"></i></button>
                                    </div>
                                </div>  
                            </div>
                            <div className="col-6 col-sm-4">
                                <div className="card">
                                    <img className='img-fluid' src={require('../lib/img/store7.jpg')} alt='Test Image Store'/>                              
                                    <div className="card-body">                                                               
                                        <h5 className="card-title">Gwangju Branch of Small & Medium Business</h5>
                                        <p className="card-text"><i className="fas fa-map-marker-alt"></i> Gwangju Station 235, Mueangro, Buk-gu, Gwangju</p>                                
                                        <button className="btn btn-outline-danger">Chi tiết <i className="fas fa-chevron-right"></i></button>
                                    </div>
                                </div>  
                            </div>
                            <div className="col-6 col-sm-4">
                                <div className="card">
                                    <img className='img-fluid' src={require('../lib/img/store8.jpg')} alt='Test Image Store'/>                              
                                    <div className="card-body">                                                               
                                        <h5 className="card-title">Small and medium-sized enterprises</h5>
                                        <p className="card-text"><i className="fas fa-map-marker-alt"></i> 294 Shinam-dong Dong-gu, Daegu Metropolitan, City Dongdaegu Station</p>                                
                                        <button className="btn btn-outline-danger">Chi tiết <i className="fas fa-chevron-right"></i></button>
                                    </div>
                                </div>  
                            </div>                                                        
                        </div>
                        {/* Store4 */}
                        <h4 className='titlePage'><i className='fas fa-fw fa-leaf'></i> Hệ thống của hàng Daedong KOREA CO., LTD tại Trung tâm thương mại cao cấp</h4>
                        <div className="row stores-list justify-content-around">                        
                            <div className="col-6 col-sm-4">
                                <div className="card">
                                    <img className='img-fluid' src={require('../lib/img/store9.jpg')} alt='Test Image Store'/>                              
                                    <div className="card-body">                                                               
                                        <h5 className="card-title">Lotte Department Store Daegu Merchant Store</h5>
                                        <p className="card-text"><i className="fas fa-map-marker-alt"></i> Monthly ship 232, Dalseo-gu, Daegu Metropolitan City, Bottom 1 red ginseng corner</p>                                
                                        <button className="btn btn-outline-danger">Chi tiết <i className="fas fa-chevron-right"></i></button>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>
                    <h2 className="mb-3 text-center mainTitlePage" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <i className="fas fa-store"></i> Hệ thống đại lý - Việt Nam
                    </h2>
                    <div id="collapseTwo" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordion">
                        <h4 className='titlePage'><i className='fas fa-fw fa-leaf'></i> Hà Nội</h4>
                        <div className="row stores-list justify-content-around">                        
                            <div className="col-6 col-sm-4">
                                <div className="card">
                                    <img className='img-fluid' src={require('../lib/img/demo-store-1.jpg')} alt='Test Image Store'/>
                                    <div className="card-body">
                                        <h5 className="card-title">VHP Ginseng - Hà Nội</h5>
                                        <p className="card-text"><i className="fas fa-map-marker-alt"></i> 27D Lý Thái Tổ, Hoàn Kiếm, Hà Nội</p>                                
                                        <button className="btn btn-outline-danger">Chi tiết <i className="fas fa-chevron-right"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-4">
                                <div className="card">
                                    <img className='img-fluid' src={require('../lib/img/demo-store-2.jpg')} alt='Test Image Store'/>
                                    <div className="card-body">
                                        <h5 className="card-title">Hồng sâm Hàn Quốc Phúc Minh</h5>
                                        <p className="card-text"><i className="fas fa-map-marker-alt"></i> 24 Nguyễn Phong Sắc, Q. Cầu Giấy, Hà Nội</p>
                                        <button className="btn btn-outline-danger">Chi tiết <i className="fas fa-chevron-right"></i></button>
                                    </div>
                                </div>
                            </div>                        
                            <div className="col-6 col-sm-4">
                                <div className="card">
                                    <img className='img-fluid' src={require('../lib/img/demo-store-1.jpg')} alt='Test Image Store'/>
                                    <div className="card-body">
                                        <h5 className="card-title">Hồng sâm Hàn Quốc Thiện Nhân</h5>
                                        <p className="card-text"><i className="fas fa-map-marker-alt"></i> Số 4, ngõ 29/2 Nguyễn Chí Thanh, Ba Đình, Hà Nội</p>
                                        <button className="btn btn-outline-danger">Chi tiết <i className="fas fa-chevron-right"></i></button>
                                    </div>
                                </div>
                            </div>                                                                        
                        </div>
                        {/* HCM */}
                        <h4 className='titlePage'><i className='fas fa-fw fa-leaf'></i> TP.HCM</h4>
                        <div className="row stores-list justify-content-around">
                            <div className="col-6 col-sm-4">
                                <div className="card">
                                    <img className='img-fluid' src={require('../lib/img/demo-store-1-SG.jpg')} alt='Test Image Store'/>
                                    <div className="card-body">
                                        <h5 className="card-title">VHP Ginseng - TP.HCM</h5>
                                        <p className="card-text"><i className="fas fa-map-marker-alt"></i> 10A Sông Đáy, P2, Q. Tân Bình, TP HCM</p>                                
                                        <button className="btn btn-outline-danger">Chi tiết <i className="fas fa-chevron-right"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-4">
                                <div className="card">
                                    <img className='img-fluid' src={require('../lib/img/demo-store-2.jpg')} alt='Test Image Store'/>
                                    <div className="card-body">
                                        <h5 className="card-title">Hồng sâm Hàn Quốc Hoa Lê</h5>
                                        <p className="card-text"><i className="fas fa-map-marker-alt"></i> 112 Phạm Văn Bạch, P15, Q, Tân Bình, TP HCM</p>
                                        <button className="btn btn-outline-danger">Chi tiết <i className="fas fa-chevron-right"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Hai Phong */}
                        <h4 className='titlePage'><i className='fas fa-fw fa-leaf'></i> Hải Phòng</h4>
                        <div className="row stores-list justify-content-around">
                            <div className="col-6 col-sm-4">
                                <div className="card">
                                    <img className='img-fluid' src={require('../lib/img/demo-store-2.jpg')} alt='Test Image Store'/>
                                    <div className="card-body">
                                        <h5 className="card-title">Daedong Korea Ginseng</h5>
                                        <p className="card-text"><i className="fas fa-map-marker-alt"></i> 14 Ký Con, Hồng Bàng, Hải Phòng</p>
                                        <button className="btn btn-outline-danger">Chi tiết <i className="fas fa-chevron-right"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-4">
                                <div className="card">
                                    <img className='img-fluid' src={require('../lib/img/demo-store-2.jpg')} alt='Test Image Store'/>
                                    <div className="card-body">
                                        <h5 className="card-title">Hồng sâm Hàn Quốc Chi Anh</h5>
                                        <p className="card-text"><i className="fas fa-map-marker-alt"></i> Hòa Nhất, Đặng Cương, An Dương, Hải Phòng</p>
                                        <button className="btn btn-outline-danger">Chi tiết <i className="fas fa-chevron-right"></i></button>
                                    </div>
                                </div>
                            </div>                        
                        </div>
                        {/* Vĩnh Phúc */}
                        <h4 className='titlePage'><i className='fas fa-fw fa-leaf'></i> Vĩnh Phúc</h4>
                        <div className="row stores-list justify-content-around">
                            <div className="col-6 col-sm-4">
                                <div className="card">
                                    <img className='img-fluid' src={require('../lib/img/demo-store-2.jpg')} alt='Test Image Store'/>
                                    <div className="card-body">
                                        <h5 className="card-title">Hồng sâm Hàn Quốc Hương Oanh</h5>                                
                                        <p className="card-text"><i className="fas fa-map-marker-alt"></i> 47 Nguyễn Viết Xuân, Ngô Quyền, Vĩnh Yên, Vĩnh Phúc</p>                            
                                        <button className="btn btn-outline-danger">Chi tiết <i className="fas fa-chevron-right"></i></button>
                                    </div>
                                </div>
                            </div> 
                            <div className="col-6 col-sm-4">
                                <div className="card">
                                    <img className='img-fluid' src={require('../lib/img/demo-store-1.jpg')} alt='Test Image Store'/>
                                    <div className="card-body">
                                        <h5 className="card-title">Hồng sâm Hàn Quốc Hương Vũ</h5>
                                        <p className="card-text"><i className="fas fa-map-marker-alt"></i> P. Phúc Thắng, Thị xã Phúc Yên, Vĩnh Phúc</p>
                                        <button className="btn btn-outline-danger">Chi tiết <i className="fas fa-chevron-right"></i></button>
                                    </div>
                                </div>
                            </div>                       
                        </div>
                        {/* Đăk Lăk */}
                        <h4 className='titlePage'><i className='fas fa-fw fa-leaf'></i> Đăk Lăk</h4>
                        <div className="row stores-list justify-content-around">
                            <div className="col-6 col-sm-4">
                                <div className="card">
                                    <img className='img-fluid' src={require('../lib/img/demo-store-1-SG.jpg')} alt='Test Image Store'/>
                                    <div className="card-body">
                                        <h5 className="card-title">Đăk Lăk</h5>
                                        <p className="card-text"><i className="fas fa-map-marker-alt"></i> Đăk Lăk</p>                                
                                        <button className="btn btn-outline-danger">Chi tiết <i className="fas fa-chevron-right"></i></button>
                                    </div>
                                </div>
                            </div>                        
                        </div>                        
                    </div>
                    
                </div>
                
                <Contact/>

                <Footer/>         
            </div>
        );
    }
}

export default Stores;