import React, { Component } from 'react';
import i18n from "i18next";
import { withNamespaces  } from 'react-i18next';

class Contact extends Component {
    componentWillMount() {
        i18n.addResourceBundle('vn', 'content-contact', {
            key: '<h2 class="title-contact">LIÊN HỆ</h2><p class="address">Daedong Korea Ginseng Co., Ltd. _ 471, Gosanja-ro, Dongdaemun-gu, Seoul</p><p style="font-weight: 500">| Liên hệ OEM | Liên hệ xuất khẩu | Liên hệ sản phẩm |</p><p class="addr-sub1"><i class="fa fa-phone-square"></i><span class="tel">02-964-8808</span><i class="fa fa-envelope"></i><span class="email">jklee@ddkorea.co.kr</span></p><a href="#" class="more-info" style="margin: 10px 0 20px">Liên Hệ Mở Đại Lý / Hợp Tác</a><p class="address">Đại diện độc quyền tại Việt Nam: VHP-Ginseng</p><p class="addr-sub1"><i class="fa fa-phone-square"></i><span class="tel">0985.118.368</span><i class="fa fa-envelope"></i><span class="email">info@vhpgroup.vn</span></p>',
        });
        i18n.addResourceBundle('en', 'content-contact', {
            key: '<h2 class="title-contact">CONTACT US</h2><p class="address">Daedong Korea Ginseng Co., Ltd. _ 471, Gosanja-ro, Dongdaemun-gu, Seoul</p><p style="font-weight: 500">| OEM inquiry | Export inquiry | Product inquiry |</p><p class="addr-sub1"><i class="fa fa-phone-square"></i><span class="tel">02-964-8808</span><i class="fa fa-envelope"></i><span class="email">jklee@ddkorea.co.kr</span></p><a href="#" class="more-info" style="margin: 10px 0 20px">Online inquiry</a>',
        });
    }
    render() {
        const currentHeight = (window.innerWidth >  767) ? window.innerHeight+'px' : 'auto';
        return (
            <div id="section-8" className="section container d-flex align-items-center justify-content-center" style={{height: currentHeight}}>                
                <div className="text-center" dangerouslySetInnerHTML={{__html: i18n.t('content-contact:key')}}/>                                     
            </div>
        );
    }
}

export default withNamespaces()(Contact);