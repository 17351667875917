import React, { Component } from 'react';
import Slider from './home-page/Slider';
import Technology from './home-page/Technology';
import Facility from './home-page/Facility';
import NewProduct from './home-page/NewProduct';
import NewEvent from './home-page/NewEvent';
import Exports from './home-page/Exports';
import Contact from './home-page/Contact';
import Footer from './footer/Footer';
import Navigation from './header/navigation';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';

class HomePage extends Component {    
    componentDidMount(){
        document.body.classList.add("isHomePage"); 
        if ($("body").hasClass("isHomePage")) {            
            window.addEventListener('wheel', function(e) {
                if (e.deltaY < 0) {                             
                    let idCurrent = $('#navi-fullpage button.active').attr('id');
                    let idUP = parseInt(idCurrent) - 1;
                    let idNext = 1;
                    if(idUP > 1){
                        idNext = idUP;
                    }                                
                    $("#navi-fullpage button").removeClass("active");
                    $("div.section").removeClass("sec-active");
                    if(document.getElementById(idNext)){
                        document.getElementById(idNext).classList.add("active");
                        document.getElementById("section-"+idNext).classList.add("sec-active");
                        document.getElementById("section-"+idNext).scrollIntoView({behavior: "smooth"});
                    }                    
                }
                if (e.deltaY > 0) {
                    let idCurrent = $('#navi-fullpage button.active').attr('id');
                    let idDOWN = parseInt(idCurrent) + 1;
                    let idNext = 9;
                    if(idDOWN < 9){
                        idNext = idDOWN;
                    }                
                    $("#navi-fullpage button").removeClass("active");
                    $("div.section").removeClass("sec-active"); 
                    if(document.getElementById(idNext)){
                        document.getElementById(idNext).classList.add("active");
                        document.getElementById("section-"+idNext).classList.add("sec-active");
                        document.getElementById("section-"+idNext).scrollIntoView({behavior: "smooth"});                
                    }                    
                }
            });
        }               
    }

    btnChangeFullPage(e, refName) {
        e.preventDefault();        
        let idChangeFullPage = "section-"+refName;
        console.log("This is id:" + refName);
        document.getElementById(idChangeFullPage).scrollIntoView({behavior: "smooth"});            
        $("#navi-fullpage button").removeClass("active");
        $("div.section").removeClass("sec-active");        
        document.getElementById(refName).classList.add("active");
        document.getElementById(idChangeFullPage).classList.add("sec-active");
    }

    render() { 
        $('body').removeAttr( "class" );                       
        return (        
            <div className="full-screen-scroller">
                
                <Navigation/>
                
                <Slider/>
            
                <Technology/>

                <Facility/>

                <NewProduct/>

                <NewEvent/>

                <Exports/>                           

                <Contact/>

                <Footer/>       

                <ul id="navi-fullpage">
                    <li><button type="button" id="1" ref="1" className="active" onClick={(e) => {this.btnChangeFullPage(e, '1')}}><i className="far fa-dot-circle"></i></button></li>
                    <li><button type="button" id="2" ref="2" onClick={(e) => {this.btnChangeFullPage(e, '2')}}><i className="far fa-dot-circle"></i></button></li>
                    <li><button type="button" id="3" ref="3" onClick={(e) => {this.btnChangeFullPage(e, '3')}}><i className="far fa-dot-circle"></i></button></li>
                    <li><button type="button" id="4" ref="4" onClick={(e) => {this.btnChangeFullPage(e, '4')}}><i className="far fa-dot-circle"></i></button></li>
                    <li><button type="button" id="5" ref="5" onClick={(e) => {this.btnChangeFullPage(e, '5')}}><i className="far fa-dot-circle"></i></button></li>
                    <li><button type="button" id="6" ref="6" onClick={(e) => {this.btnChangeFullPage(e, '6')}}><i className="far fa-dot-circle"></i></button></li>
                    <li><button type="button" id="7" ref="7" onClick={(e) => {this.btnChangeFullPage(e, '7')}}><i className="far fa-dot-circle"></i></button></li>
                    <li><button type="button" id="8" ref="8" onClick={(e) => {this.btnChangeFullPage(e, '8')}}><i className="far fa-dot-circle"></i></button></li>
                    <li><button type="button" id="9" ref="9" onClick={(e) => {this.btnChangeFullPage(e, '9')}}><i className="far fa-dot-circle"></i></button></li>

                </ul>         
                
            </div>
        );
    }
}

export default withRouter(HomePage);