import React, { Component } from 'react';
import Navigation from '../header/navigation';
import Contact from '../home-page/Contact';
import Footer from '../footer/Footer';
import $ from 'jquery';

class CeoDaedong extends Component {
    componentDidMount(){
        document.body.classList.add("isCeoDaedong");  
    }
    render() {
        $('body').removeAttr( "class" );
        return (
            <div className="full-screen-scroller">
                <Navigation/>
                <div id="breadcrumbs" style={{backgroundImage: "url(" + require('../../lib/img/bgtop-company-5.jpg') + ")"}}></div>
                <div id="breadcrumbs_box">
                    <div className="container clearfix text-right">
                        <i className="fas fa-home"></i> <a href="/">Trang chủ</a> <i className="fas fa-angle-double-right" style={{fontSize:"10px"}}></i> Lời chào CEO
                    </div>
                </div>
                <div className="container" style={{paddingTop: "40px"}}>
                    <h1 className="title-page">Lời chào CEO</h1>                    
                    <h5 className="sub-title-page"><i className="fas fa-quote-left"></i>Daedong Korea Ginseng Co., Ltd là thương hiệu tiêu biểu đại diện Hàn quốc chuyên sản xuất các dòng sản phẩm <br/>“Nhân sâm Hàn quốc” (高麗人蔘)</h5>
                    <div className="row" style={{paddingTop: "40px"}}>
                        <div className="col-12">
                            <h2 className="h2-title-page">| Lời chào đến từ Chủ tịch tập đoàn Daedong Korea Ginseng<br/>"Choi Sung Geun"</h2>
                            <h5 className="sub-title-page text-justify"><i className="fas fa-quote-left"></i>
                                Chào mừng đến với Daedong Korea Ginseng Co., Ltd - một doanh nghiệp luôn giữ vững sự trung thực, chính xác và chân thành.
                            </h5>
                            <p className="text-justify">
                                Daedong Korea Ginseng Co., Ltd chuyên sản xuất nhân sâm - một sản phẩm đại diện văn hóa Hàn Quốc. Chúng tôi đảm bảo an toàn thực phẩm từ nguồn nguyên liệu thô đến chế biến, sản xuất và phân phối thông qua tiêu chuẩn kiểm soát chất lượng nghiêm ngặt.
                            </p>
                            <p className="text-justify">
                                Ngoài ra, chúng tôi đang nỗ lực đền đáp lại niềm tin của khách hàng bằng cách phát triển công nghệ, thúc đẩy hệ thống nghiên cứu R&D.
                            </p>
                        </div>
                    </div>
                    <div className="row" style={{marginTop:'40px'}}>
                        <div className="col-12 col-sm-5">
                            <img src={require('../../lib/img/CEO_Daedong.jpg')} className="img-fluid mx-auto d-block" alt="CEO Daedong"/>
                        </div>
                        <div className="col-12 col-sm-7" style={{padding:"0 30px"}}>
                            <h5>ĐẦU ÓC SÁNG  - TRÁI TIM MẠNH – CƠ THỂ KHỎE</h5>
                            <p className="text-justify">
                                Chúng tôi cố gắng cải thiện chất lượng cuộc sống khách hàng với tinh thần của CHEONG-MYEONG-GUN (淸明健) (Trung thực – Chính xác – Tận tâm)
                            </p>
                            <p className="text-justify">
                                Chúng tôi cũng đang nỗ lực để phát triển dược phẩm tập trung sử dụng nguyên liệu thô trong đông y.
                            </p>
                            <p className="text-justify">
                                Daedong Korea Ginseng Co., Ltd trong tương lai sẽ được tái sinh thành một trong những doanh nghiệp toàn cầu tầm cỡ thế giới với sự thâm nhập thị trường và tiếp thị vượt ra ngoài khu vực Đông Nam Á, Mỹ và các nước châu Âu.
                            </p>
                            <p className="text-justify">
                                Trong suốt thời gian vừa qua, các đồng sự trong công ty chúng tôi đã cùng nhau vượt qua rất nhiều khó khăn và đạt được sự tiến bộ với niềm đam mê và tham vọng lớn.
                            </p>
                            <p className="text-justify">
                                Bên cạnh đó, chúng tôi sẽ không quên khi tiếp tục thực hiện trách nhiệm xã hội của mình và đóng góp không ngừng cho hạnh phúc của nhân loại!
                            </p>
                            <div className="row">
                                <div className="col-4 text-right">
                                    Ký tên,<br/>
                                    CEO Daedong Korea Ginseng Co., Ltd<br/>
                                    Choi Sung Geun
                                </div>
                                <div className="col-8">
                                    <img src={require('../../lib/img/chu_ky_CEO_Daedong.jpg')} className="img-fluid" alt="CEO Daedong"/>
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>
                
                <Contact/>

                <Footer/>

            </div>
        );
    }
}

export default CeoDaedong;