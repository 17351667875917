import React, { Component } from 'react';
import Navigation from '../header/navigation';
import Contact from '../home-page/Contact';
import Footer from '../footer/Footer';
import $ from 'jquery';

class ProductsList extends Component {
    componentDidMount(){
        document.body.classList.add("isProductList");
    }
    render() {
        $('body').removeAttr( "class" );
        return (
            <div className="full-screen-scroller porudct-list">
                <Navigation />
                <div id="breadcrumbs" style={{ backgroundImage: "url(" + require('../../lib/img/bgtop-product-red-ginseng.jpg') + ")" }}></div>
                <div id="breadcrumbs_box">
                    <div className="container clearfix text-right">
                        <i className="fas fa-home"></i> <a href="/">Trang chủ</a> <i className="fas fa-angle-double-right" style={{ fontSize: "10px" }}></i> Danh sách sản phẩm
                    </div>
                </div>
                <div className="container" style={{ paddingTop: "40px" }}>
                    <h1 className="title-page"><img className="icon-daedong" src={require('../../lib/img/favi-icon-Daeodong.png')} /> Danh sách sản phẩm</h1>                    
                        {/* Item rou product */}
                    <div className="row wrap-product d-flex justify-content-center">
                        <div className="col-6 col-sm-4">
                            <div className="card item-product text-center">
                                <a href='/product'><img className="img-fluid mx-auto d-block" src={require('../../lib/img/tinh-chat-hac-sam-10ml.jpg')} alt="Card image cap" /></a>
                                <div className="card-body">
                                    <h5 className="card-title">Tinh chất hắc sâm 10ml x 30 gói</h5>
                                    <div className="card-text">100% từ hắc sâm 6 năm tuổi Hàn Quốc</div>
                                    <p className="main-price">3,850,000 VNĐ</p>
                                </div>
                                <div className="card-footer">
                                    <a href="/product" className="title-product">Chi tiết <i className="fas fa-angle-double-right"></i></a>
                                </div>
                            </div>                            
                        </div>
                        <div className="col-6 col-sm-4">
                            <div className="card item-product text-center">
                                <a href='/product'><img className="img-fluid mx-auto d-block" src={require('../../lib/img/cao-hong-240gr-daedong.jpg')} alt="Card image cap" /></a>
                                <div className="card-body">
                                    <h5 className="card-title">Cao hồng sâm 240gr</h5>
                                    <div className="card-text">100% từ hồng sâm 6 năm tuổi Hàn Quốc</div>
                                    <p className="main-price">2,800,000 VNĐ</p>
                                </div>
                                <div className="card-footer">
                                    <a href="/product" className="title-product">Chi tiết <i className="fas fa-angle-double-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4">
                            <div className="card item-product text-center">
                                <a href='/product'><img className="img-fluid mx-auto d-block" src={require('../../lib/img/cao-hac-sam-120gr-daedong.jpg')} alt="Card image cap" /></a>
                                <div className="card-body">
                                    <h5 className="card-title">Cao hắc sâm nguyên chất 120gr</h5>
                                    <div className="card-text">100% từ hắc sâm cao cấp nguyên chất</div>
                                    <p className="main-price">3,550,000 VNĐ</p>
                                </div>
                                <div className="card-footer">
                                    <a href="/product" className="title-product">Chi tiết <i className="fas fa-angle-double-right"></i></a>
                                </div>
                            </div>
                        </div>                        
                        <div className="col-6 col-sm-4">
                            <div className="card item-product text-center">
                                <a href='/product'><img className="img-fluid mx-auto d-block" src={require('../../lib/img/hac-sam-cu-kho-thai-lat.jpg')} alt="Card image cap" /></a>
                                <div className="card-body">
                                    <h5 className="card-title">Hắc sâm củ khô thái lát</h5>
                                    <div className="card-text">100% từ hắc sâm 6 năm tuổi Hàn Quốc</div>
                                    <p className="main-price">6,000,000 VNĐ</p>
                                </div>
                                <div className="card-footer">
                                    <a href="/product" className="title-product">Chi tiết <i className="fas fa-angle-double-right"></i></a>
                                </div>
                            </div>                            
                        </div>
                        <div className="col-6 col-sm-4">
                            <div className="card item-product text-center">
                                <a href='/product'><img className="img-fluid mx-auto d-block" src={require('../../lib/img/sam-cu-tam-mat-ong.jpg')} alt="Card image cap" /></a>
                                <div className="card-body">
                                    <h5 className="card-title">Hồng sâm củ tẩm mật ong</h5>
                                    <div className="card-text">100% từ hồng sâm 6 năm tuổi Hàn Quốc</div>
                                    <p className="main-price">1,250,000 VNĐ</p>
                                </div>
                                <div className="card-footer">
                                    <a href="/product" className="title-product">Chi tiết <i className="fas fa-angle-double-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4">
                            <div className="card item-product text-center">
                                <a href='/product'><img className="img-fluid mx-auto d-block" src={require('../../lib/img/sam-lat-tam-mat-ong.jpg')} alt="Card image cap" /></a>
                                <div className="card-body">
                                    <h5 className="card-title">Hồng sâm thái lát tẩm mật ong</h5>
                                    <div className="card-text">100% từ hồng sâm 6 năm tuổi Hàn Quốc</div>
                                    <p className="main-price">900,000 VNĐ</p>
                                </div>
                                <div className="card-footer">
                                    <a href="/product" className="title-product">Chi tiết <i className="fas fa-angle-double-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4">
                            <div className="card item-product text-center">
                                <a href='/product'><img className="img-fluid mx-auto d-block" src={require('../../lib/img/keo-hac-sam-daedong.jpg')} alt="Card image cap" /></a>
                                <div className="card-body">
                                    <h5 className="card-title">Kẹo hắc sâm - Daedong</h5>
                                    <div className="card-text">Hắc sâm 6 năm tuổi Hàn Quốc</div>
                                    <p className="main-price">90,000 VNĐ</p>
                                </div>
                                <div className="card-footer">
                                    <a href="/product" className="title-product">Chi tiết <i className="fas fa-angle-double-right"></i></a>
                                </div>
                            </div>                            
                        </div>
                        <div className="col-6 col-sm-4">
                            <div className="card item-product text-center">
                                <a href='/product'><img className="img-fluid mx-auto d-block" src={require('../../lib/img/keo-hong-sam-daeong-500gr.jpg')} alt="Card image cap" /></a>
                                <div className="card-body">
                                    <h5 className="card-title">Kẹo hồng sâm - Daedong</h5>
                                    <div className="card-text">Hồng sâm 6 năm tuổi Hàn Quốc</div>
                                    <p className="main-price has-sale">160,000 VNĐ</p>
                                    <p className="sale-price">80,000 VNĐ</p>
                                </div>
                                <div className="card-footer">
                                    <a href="/product" className="title-product">Chi tiết <i className="fas fa-angle-double-right"></i></a>
                                </div>
                            </div>                            
                        </div>
                    </div>
                
                </div>
                <Contact />

                <Footer />
            </div>
        );
    }
}

export default ProductsList;