import React, { Component } from 'react';
import i18n from "i18next";
import { withNamespaces  } from 'react-i18next';

class Slider extends Component {

    componentWillMount() {      
        
        //Silde 1
        i18n.addResourceBundle('vn', 'titleslide0', {
            key: 'Trung thực - tận tâm - chính xác<br/>đạt tiêu chuẩn quốc tế.',
        });
        i18n.addResourceBundle('vn', 'desslide0', {
            key: 'Kinh doanh dựa trên sự tận tâm cùng 3 Nguyên Tắc Vàng<br/>Cheongmyeong-gun (淸明健): TRUNG THỰC, CHÍNH XÁC, TẬN TÂM,<br />Hạnh phúc luôn là ưu tiên hàng đầu của chúng tôi.',
        });
        i18n.addResourceBundle('en', 'titleslide0', {
            key: 'A company that plants honesty <br />and makes accurate products with sincerity.',
        });
        i18n.addResourceBundle('en', 'desslide0', {
            key: 'Based on mind attitude of Cheong-myeong-gun (Clear, Bright and Firm)<br />and 3 principals (Honesty, Accuracy, and Sincerity),<br />we put top priority on our customer’s health and happiness.',
        });

        //Slide2
        i18n.addResourceBundle('vn', 'titleslide1', {
            key: 'Doanh nghiệp hàng đầu sản xuất các sản phẩm<br/>có nguồn gốc từ Nhân Sâm Hàn Quốc',
        });
        i18n.addResourceBundle('vn', 'desslide1', {
            key: 'Chúng tôi không ngừng phấn đấu trở thành một doanh nghiệp toàn cầu<br/>vượt ra ngoài thị trường trong nước, tiến xa thị trường quốc tế.',
        });
        i18n.addResourceBundle('en', 'titleslide1', {
            key: 'A company that makes a ginseng road of Korea ginseng',
        });
        i18n.addResourceBundle('en', 'desslide1', {
            key: 'We are striving to become a global company that focuses on marketing<br />and entering global markets beyond domestic markets.',
        });

        //Slide3
        i18n.addResourceBundle('vn', 'titleslide2', {
            key: 'Tự hào với lý tưởng mở ra một tương lai “khỏe mạnh”<br/>thông qua những nghiên cứu & phát triển sản phẩm không ngừng',
        });
        i18n.addResourceBundle('vn', 'desslide2', {
            key: 'Chúng tôi nỗ lực phát triển các dòng sản phẩm chức năng có nguồn gốc từ nhân sâm Hàn Quốc như<br/>Sâm tươi, Thái cực sâm, Hồng sâm, Hắc sâm, cùng với các dòng sản phẩm kết hợp<br/>dựa trên nguyên liệu thảo dược bổ dưỡng.',
        });
        i18n.addResourceBundle('en', 'titleslide2', {
            key: 'A company that opens a healthy future <br />with continuous research and development',
        });
        i18n.addResourceBundle('en', 'desslide2', {
            key: 'We make an effeort to develop Korea ginseng products<br />such as ginseng, Taekuksam, red ginseng, and black ginseng,<br />and pharmaceutical products based on raw materials of oriental medicine.',
        });

        //Slide4
        i18n.addResourceBundle('vn', 'titleslide3', {
            key: 'Là một trong những doanh nghiệp hàng đầu, tiên phong xây dựng<br/> một đời sống văn hóa lành mạnh của Hàn Quốc',
        });
        i18n.addResourceBundle('vn', 'desslide3', {
            key: 'Chúng tôi chịu trách nhiệm và tài trợ cho các Hoạt động thể thao của Thanh thiếu niên địa phương<br/>(Cây mơ ước – Dream tree), nhằm hướng tới 1  tương lai khỏe mạnh của Đại Hàn Dân Quốc.',
        });
        i18n.addResourceBundle('vn', 'name-slide4', {
            key: '| Cây mơ ước Taekwondo trẻ | Jeon, Hyun-sik'
        });
        i18n.addResourceBundle('en', 'name-slide4', {
            key: '| A dream tree of Taekwondo | Jeon, Hyun-sik'
        });
        i18n.addResourceBundle('en', 'titleslide3', {
            key: 'A company that leads healthy cultural life of Korea',
        });
        i18n.addResourceBundle('en', 'desslide3', {
            key: 'We support the promising sport youth (a dream tree),<br />who can make healthy future of Korea, of each district.',
        });
    }

    render() {     
        const currentHeight = (window.innerWidth >  767) ? window.innerHeight+'px' : 'auto';
        return (            
            <div id="section-1" className="section sec-active carousel slide" data-ride="carousel" style={{height: currentHeight}}>
                <ol className="carousel-indicators">
                    <li data-target="#section-1" data-slide-to="0" className="active"></li>
                    <li data-target="#section-1" data-slide-to="1"></li>
                    <li data-target="#section-1" data-slide-to="2"></li>
                    <li data-target="#section-1" data-slide-to="3"></li>
                </ol>
                <div className="carousel-inner">
                    <div className="carousel-item bg active" style={{ backgroundImage: "url(" + require('../../lib/img/ddk-mainslide-01.jpg') + ")" }} data-interval="10000">
                        <div className="container">
                            <div className="carousel-caption text-left">
                                <h2 dangerouslySetInnerHTML={{__html: i18n.t('titleslide0:key')}}></h2>
                                <p className="description" dangerouslySetInnerHTML={{__html: i18n.t('desslide0:key')}}></p>                                
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item bg" style={{ backgroundImage: "url(" + require('../../lib/img/ddk-mainslide-02.jpg') + ")" }} data-interval="10000">
                        <div className="container">
                            <div className="carousel-caption text-left">
                                <h2 dangerouslySetInnerHTML={{__html: i18n.t('titleslide1:key')}}></h2>
                                <p className="description" dangerouslySetInnerHTML={{__html: i18n.t('desslide1:key')}}></p>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item bg" style={{ backgroundImage: "url(" + require('../../lib/img/ddk-mainslide-03.jpg') + ")" }} data-interval="10000">
                        <div className="container">
                            <div className="carousel-caption text-left">
                                <h2 dangerouslySetInnerHTML={{__html: i18n.t('titleslide2:key')}}></h2>
                                <p className="description" dangerouslySetInnerHTML={{__html: i18n.t('desslide2:key')}}></p>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item bg" style={{ backgroundImage: "url(" + require('../../lib/img/ddk-mainslide-04.jpg') + ")" }} data-interval="10000">
                        <div className="container">
                            <div className="carousel-caption text-left">
                                <h2 dangerouslySetInnerHTML={{__html: i18n.t('titleslide3:key')}}></h2>
                                <p className="description" dangerouslySetInnerHTML={{__html: i18n.t('desslide3:key')}}></p>
                                <i className="name-slide4" dangerouslySetInnerHTML={{__html: i18n.t('name-slide4:key')}}/>
                            </div>
                        </div>
                    </div>
                </div>
                <a className="carousel-control-prev" href="#section-1" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#section-1" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
            </div>            
        );
    }
}

export default withNamespaces()(Slider);