import React, { Component } from 'react';
import i18n from "i18next";
import { withNamespaces  } from 'react-i18next';

class NewProduct extends Component {
    componentWillMount() {
        i18n.addResourceBundle('vn', 'title-newproduct', {
            key: 'THƯƠNG HIỆU SẢN PHẨM MỚI',
        });        
        i18n.addResourceBundle('en', 'title-newproduct', {
            key: 'NEW PRODUCTS HISTORY',
        });

        i18n.addResourceBundle('vn', 'title-newproduct1', {
            key: 'Tinh hoa truyền thống & công nghệ hiện đại<br/>&middot;&middot;&middot;&nbsp;Daedong Korea ginseng Co., Ltd&nbsp;&middot;&middot;&middot;&nbsp;&nbsp;진쎈 _ GINSSEN',
        });        
        i18n.addResourceBundle('en', 'title-newproduct1', {
            key: 'Wisdom of ancestors into science… Daedong Korea ginseng Co., Ltd._ GINSSEN',
        });

        i18n.addResourceBundle('vn', 'content-newproduct', {
            key: '<p>Thương hiệu sản phẩm mới nhất từ tập đoàn Daedong Korea Ginseng Co.,LTd: Daedong Ginssen (진쎈)  - là dòng thương hiệu dành riêng cho các sản phẩm HẮC SÂM, được chế biến từ nhân sâm trải qua quá trình hấp sấy liên tục (구증구포 -九蒸九曝 - 9 lần hấp 9 lần sấy) tạo nên màu đen; trong khi đó hồng sâm thường chỉ hấp sấy 3 lần</p><p><a href="/about-ginssen" class="more-info">Xem thêm về GINSSEN 진쎈</a></p>',
        });        
        i18n.addResourceBundle('en', 'content-newproduct', {
            key: '<p>GINSSEN, a black ginseng brand of Daedong Korea ginseng Co., Ltd selects only excellent ginsengs and goes through a process of 9 times-steaming and 9 times-drying (Gu-jeung-gu-po). This is a premium brand that delivers the unique taste and flavor as it is.</p><p><a href="/about-ginssen" class="more-info">Detail view of GINSSEN</a></p>',
        });

    }
    render() {
        const currentHeight = (window.innerWidth >  767) ? window.innerHeight+'px' : 'auto';
        return (
            <div id="section-4" className="section row new-product d-flex align-items-center" style={{height: currentHeight, backgroundImage: "url(" + require('../../lib/img/ddk-GINSSEN_01a.jpg') + ")", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: "center center", margin: 0 }}>
                <div className="col-md-2">&nbsp;</div>
                <div className="col-md-5 text-center">
                    <h2 className="featurette-heading">{i18n.t('title-newproduct:key')}</h2>
                    <p className="lead" style={{marginTop: '15px'}} dangerouslySetInnerHTML={{__html: i18n.t('title-newproduct1:key')}}/>
                    <div dangerouslySetInnerHTML={{__html: i18n.t('content-newproduct:key')}}/>
                </div>
                <div className="col-md-5 d-flex justify-content-center">
                    <img className="img-fluid" alt="ddk-global-map-eng" src={require('../../lib/img/ddk-GINSSEN_product_120g_a.png')} />
                </div>
            </div>
        );
    }
}

export default withNamespaces()(NewProduct);