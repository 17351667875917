import React, { Component } from 'react';
import Navigation from '../header/navigation';
import Contact from '../home-page/Contact';
import Footer from '../footer/Footer';
import $ from 'jquery';

class DaedongStory extends Component {
    componentDidMount(){
        document.body.classList.add("isDaedongStory");  
    }
    render() {
        $('body').removeAttr( "class" );
        return (
            <div className="full-screen-scroller">
                <Navigation/>
                <div id="breadcrumbs" style={{backgroundImage: "url(" + require('../../lib/img/bgtop-company-5.jpg') + ")"}}></div>
                <div id="breadcrumbs_box">
                    <div className="container clearfix text-right">
                        <i className="fas fa-home"></i> <a href="/">Trang chủ</a> <i className="fas fa-angle-double-right" style={{fontSize:"10px"}}></i> Giới thiệu - Daedong
                    </div>
                </div>
                <div className="container" style={{paddingTop: "40px"}}>
                    <h1 className="title-page">Giới thiệu - Daedong Korea Ginseng</h1>                    
                    <h5 className="sub-title-page text-justify"><i className="fas fa-quote-left"></i>Daedong Korea Ginseng Co., Ltd đang nỗ lực từng ngày trở thành 1 doanh nghiệp phát triển toàn diện, với tiêu chí chính: Trung thực – Chính xác – Chân thành.</h5>                        
                    <h2 className="h2-title-page text-justify" style={{margin: "40px 0 30px"}}>| Tâm trí luôn giữ vững dựa trên 3 nguyên tắc tận tâm Cheongmyeong-gun (淸明健) (Cheongjik – 정직- Trung thực, Cheonghwak – 정확 – Chính xác, Cheonseong – 정성 – Chân thành)</h2>
                    <p className="text-justify" style={{fontSize:"18px"}}>Nguyên tắc Cheongmyeong-gun (淸明健), bắt nguồn với ý nghĩa: Đầu óc SÁNG, Trái tim KHỎE , Cơ thể MẠNH. Daedong Korea Ginseng Co., Ltd đặt sức khỏe và hạnh phúc của khách hàng là ưu tiên số 1, dựa trên 3 nguyên tắc tận tâm (Trung thực, Chính xác, Chân thành)</p>
                    <img src={require('../../lib/img/daedong-story-01.jpg')} className="img-fluid mx-auto d-block" alt="Deadong Story"/>
                    <h2 className="h2-title-page text-justify" style={{margin: "40px 0 30px"}}>| Sơ lược về Daedong</h2>
                    <div className="des-qulity-control row">
                        <ul className="col-12 col-sm-12 col-md-6">                                    
                            <li>Tên công ty: Daedong Korea Ginseng Co.,Ltd</li>
                            <li>Đại diện: Choi Seong-Geun</li>
                            <li>Lĩnh vực hoạt động: Sản xuất và kinh doanh các sản phẩm Nhân sâm</li>
                            <li>Thành lập: Ngày 1 tháng 4 năm 2002</li>
                            <li>Trụ sở chính: 586 Gunbuk-ro, Gunbuk-myeon, Geumsan-gun, Chungnam, South Korea</li>
                            <li>Điện thoại: 82-41-753-8803 | Fax: 82-41-753-9914</li>
                            <li>Văn phòng Seoul: Sambo Bldg, 930-7, Jegi 2-dong, Dongdaemun-gu, Seoul, South Korea</li>
                            <li>Điện thoại: 82-2-964-8808 | Fax: 82-2-964-8803</li>
                        </ul> 
                        <div className="col-12 col-sm-12 col-md-6 video">                            
                            <iframe className="mx-auto d-block" src="https://www.youtube.com/embed/fMfYij3DbDs" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>                           
                    </div>
                    <div className="row" style={{marginTop:"60px"}}>
                        <div className="col-4"><img src={require('../../lib/img/daedong-story-02.jpg')} className="img-fluid mx-auto d-block" alt="Deadong Story"/></div>
                        <div className="col-4"><img src={require('../../lib/img/daedong-story-03.jpg')} className="img-fluid mx-auto d-block" alt="Deadong Story"/></div>
                        <div className="col-4"><img src={require('../../lib/img/daedong-story-04.jpg')} className="img-fluid mx-auto d-block" alt="Deadong Story"/></div>
                    </div>
                </div>
                

                <Contact/>

                <Footer/>
                
            </div>
        );
    }
}

export default DaedongStory;