import React, { Component } from 'react';
import Navigation from '../header/navigation';
import Contact from '../home-page/Contact';
import Footer from '../footer/Footer';
import $ from 'jquery';

class QulityControl extends Component {
    componentDidMount(){
        document.body.classList.add("isQulityControl");
    }
    render() {
        $('body').removeAttr( "class" );
        return (
            <div className="full-screen-scroller">
                <Navigation/>
                <div id="breadcrumbs" style={{backgroundImage: "url(" + require('../../lib/img/bgtop-business-2.jpg') + ")"}}></div>
                <div id="breadcrumbs_box">
                    <div className="container clearfix text-right">
                        <i className="fas fa-home"></i> <a href="/">Trang chủ</a> <i className="fas fa-angle-double-right" style={{fontSize:"10px"}}></i> Kiểm soát chất lượng
                    </div>
                </div>
                <div className="container" style={{paddingTop: "40px"}}>
                    <h1 className="title-page">Kiểm soát chất lượng</h1>                    
                    <h5 className="sub-title-page text-justify"><i className="fas fa-quote-left"></i>Daedong Korea Ginseng Ltd tiến hành kiểm tra chất lượng cho tất cả các quá trình sản xuất liên quan tới sản phẩm và tiến hành kiểm tra độ an toàn nhằm đảm bảo chất lượng sản phẩm cho đến khi sản phẩm được vận chuyển.</h5>
                    <h2 className="h2-title-page" style={{margin:"30px 0"}}>| Hệ thống kiểm soát chất lượng</h2>
                    <img src={require('../../lib/img/quality-01.jpg')} className="img-fluid mx-auto d-block" alt="Quality Control"/>
                    <div className="row" style={{paddingTop: "40px"}}>
                        <div className="col-12">
                            <h5> 1. Quản lý có hệ thống </h5>
                            <p className="text-justify">                                
                                Daedong Korea Ginseng Ltd  thực hiện kiểm tra chất lượng cho tất cả các quá trình sản xuất liên quan như nguyên liệu ban đầu, thành phần dược liệu phụ, các bước quá trình sản xuất, thành phẩm khi được sản xuất ra, kiểm tra an toàn… tiến hành thử nghiệm định kỳ sau khi giao hàng nhằm đảm bảo chất lượng sản phẩm.
                            </p>
                            <h5 className="">Quy trình quản lý hệ thống: </h5>
                            <div className="col-12 text-des-odm des-qulity-control row" style={{marginTop:"0"}}>
                                <ul className="col-12 col-sm-6">                                    
                                    <li>Kỹ thuật sản xuất công nghệ cao</li>
                                    <li>Kiểm tra nguyên phụ liệu</li>
                                    <li>Cài đặt tiêu chuẩn cho sản phẩm</li>
                                    <li>Đảm bảo sự an toàn của nguyên liệu và sản phẩm (phân tích và quản lý các chất độc hại …)</li>
                                    <li>Kiểm tra dòng sản phẩm</li>
                                    <li>Kiểm tra cơ sở, tiện ích sản xuất</li>
                                    <li>Kiểm tra khiếm khuyết trong quá trình xử lý và phân tích nguyên nhân lỗi</li>
                                </ul>
                                <ul className="col-12 col-sm-6">
                                    <li>Đặt tiêu chí sản xuất theo quy trình khoa học</li>
                                    <li>Đóng gói – Vận chuyển theo kế hoạch</li>
                                    <li>Phân phối, thời gian lưu giữ, hướng dẫn sử dụng</li>
                                    <li>Kiểm tra vệ sinh khu vực và đào tạo vệ sinh lao động</li>
                                    <li>Yêu cầu xử lý phát sinh</li>
                                    <li>Ghi lại và lưu trữ tất cả các công việc</li>
                                    <li>Quản lý theo dõi lịch sử thực phẩm</li>
                                </ul>
                            </div>
                            <img src={require('../../lib/img/quality-02.jpg')} className="img-fluid mx-auto d-block" alt="Quality Control"/>
                            <h5 style={{marginTop:"40px"}}>2. Hệ thống phân tích quá trình chính xác</h5>
                            <p className="text-justify">                                
                                Tại nhà máy Daedong, thông qua hệ thống QC toàn diện, cán bộ nhân viên tiến hành quản lý sản phẩm, trọng lượng, diện mạo (tình trạng in ấn, đóng gói) và kiểm tra (tự kiểm tra) về chất lượng, khối lượng và hàm lượng chất dinh dưỡng trước khi tiến hành phân phối.
                            </p>
                            <h5 className="">Kiểm tra kỹ thuật sản phẩm: </h5>
                            <div className="col-12 text-des-odm des-qulity-control row" style={{marginTop:"0"}}>
                                <ul className="col-12">                                    
                                    <li>Kiểm tra an toàn (phân tích thuốc trừ sâu dư, phân tích benzopyrene, phân tích kim loại nặng …)</li>
                                    <li>Kiểm soát chất lượng sản phẩm (phân tích hóa lý, phân tích vi sinh học, kiểm tra phản ứng thực phẩm)</li>
                                    <li>Thiết bị kiểm tra chính: <br/>
                                    (HPLC-UV, HPLC-FLD), Chromatography khí (GC-NPD, GC-ECD), quang phổ UV Spectrophotometer, kính hiển vi quang học, kỹ thuật số Brix Meter, Lồng ấp, máy phân tích độ ẩm hồng ngoại, máy đo độ nhớt, mức phân hủy,...</li>                                    
                                </ul>                                
                            </div>
                            <img src={require('../../lib/img/quality-03.jpg')} className="img-fluid mx-auto d-block" alt="Quality Control"/>
                            <h5 style={{marginTop:"40px"}}>3. Quy trình kiểm tra chặt chẽ nguyên liệu, dược liệu phụ</h5>
                            <p className="text-justify">                                
                                Chọn nguyên liệu phù hợp theo từng đặc tính của mõi sản phẩm, kiểm tra sự phù hợp, tính cân bằng của các thành phần, đạt tiêu chuẩn được phép đưa vào sử dụng trong thực phẩm.
                            </p>
                            <h5 className="">Quy trình kiểm tra: </h5>
                            <div className="col-12 text-des-odm des-qulity-control row" style={{marginTop:"0"}}>
                                <ul className="col-12 col-sm-6">   
                                    <li>Kiểm tra nguyên liệu, dược liệu phụ</li>
                                    <li>Kiểm tra kho</li>
                                    <li>Thử nghiệm độ bền, phù hợp của thành phần vật liệu thô</li>
                                </ul>    
                                <ul className="col-12 col-sm-6">   
                                    <li>Thử nghiệm thành phần</li>
                                    <li>Phân tích thành phần nguy hiểm</li>
                                    <li>Đặc điểm kỹ thuật và xét nghiệm vi sinh vật</li>
                                </ul>                                
                            </div>
                        </div>                        
                        {/* Begin slide */}
                        <div className="col-12 quality-silde d-flex justify-content-center">
                            <div id="carouselExampleIndicators" className="carousel slide col-10" data-ride="carousel">                                                                
                                <ol className="carousel-indicators">
                                    <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                                </ol>
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img className="img-fluid mx-auto d-block" src={require('../../lib/img/quality-slide1.jpg')} alt="First slide"/>
                                    </div>
                                    <div className="carousel-item">
                                        <img className="img-fluid mx-auto d-block" src={require('../../lib/img/quality-slide2.jpg')} alt="Second slide"/>
                                    </div>
                                    <div className="carousel-item">
                                        <img className="d-block img-fluid mx-auto" src={require('../../lib/img/quality-slide3.jpg')} alt="Third slide"/>
                                    </div>
                                    <div className="carousel-item">
                                        <img className="d-block img-fluid mx-auto" src={require('../../lib/img/quality-slide4.jpg')} alt="Third slide"/>
                                    </div>
                                </div>                                
                            </div>                                                    
                        </div>
                        <div className="col-12 d-flex justify-content-center" data-ride="carousel">
                            <div className="col-10 btn-slide row">
                                <a className="col-2 text-center" href="#carouselExampleIndicators" role="button" data-slide="prev" style={{borderLeft: "1px solid #ddd", borderRight: "1px solid #ddd"}}>
                                    <i className="fas fa-angle-left"></i>&nbsp;&nbsp;Previous
                                </a>
                                <div className="col-8 text-center">
                                </div>
                                <a className="col-2 text-center" href="#carouselExampleIndicators" role="button" data-slide="next" style={{borderRight: "1px solid #ddd", borderLeft: "1px solid #ddd"}}>
                                    Next&nbsp;&nbsp;<i className="fas fa-angle-right"></i>
                                </a>
                            </div>  
                        </div>
                    </div>
                </div>                

                <Contact/>

                <Footer/>                
            </div>
        );
    }
}

export default QulityControl;