import React, { Component } from 'react';
import i18n from "i18next";
import { withNamespaces  } from 'react-i18next';

class NewEvent extends Component {
    componentWillMount() {
        i18n.addResourceBundle('vn', 'title-news', {
            key: 'Tin tức - Sự kiện',
        });        
        i18n.addResourceBundle('en', 'title-news', {
            key: 'News',
        });
    }
    render() {
        const currentHeight = (window.innerWidth >  767) ? window.innerHeight+'px' : 'auto';
        return (
            <div id="section-5" className="section container d-flex align-items-center" style={{height: currentHeight}}>
                <div className=" blog-news-event">
                    <div className="col-md-12">
                        <h3 className="title_blog-news-event text-center">{i18n.t('title-news:key')}</h3>
                    </div>
                    <div className="row mb-2">
                        <div className="col-md-6">
                            <div className="card flex-md-row mb-4 box-shadow h-md-250">
                                <div className="card-body d-flex flex-column align-items-start">
                                    <strong className="d-inline-block mb-2 text-primary">Korea</strong>
                                    <h3 className="mb-0">
                                        <a className="text-dark" href="#">Featured post</a>
                                    </h3>
                                    <div className="mb-1 text-muted">2019/02/12</div>
                                    <p className="card-text mb-auto">This is a wider card with supporting text below as a natural lead-in to additional content.</p>
                                    <a href="#">Continue reading</a>
                                </div>
                                <img className="card-img-right flex-auto d-none d-md-block" alt="Thumbnail [200x250]" src={require('../../lib/img/blog1.jpg')} data-holder-rendered="true" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card flex-md-row mb-4 box-shadow h-md-250">
                                <div className="card-body d-flex flex-column align-items-start">
                                    <strong className="d-inline-block mb-2 text-success">Vietnam</strong>
                                    <h3 className="mb-0">
                                        <a className="text-dark" href="#">Post title</a>
                                    </h3>
                                    <div className="mb-1 text-muted">2019/02/12</div>
                                    <p className="card-text mb-auto">This is a wider card with supporting text below as a natural lead-in to additional content.</p>
                                    <a href="#">Continue reading</a>
                                </div>
                                <img className="card-img-right flex-auto d-none d-md-block" alt="Thumbnail [200x250]" src={require('../../lib/img/blog2.jpg')} data-holder-rendered="true" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card flex-md-row mb-4 box-shadow h-md-250">
                                <div className="card-body d-flex flex-column align-items-start">
                                    <strong className="d-inline-block mb-2 text-primary">Korea</strong>
                                    <h3 className="mb-0">
                                        <a className="text-dark" href="#">Featured post</a>
                                    </h3>
                                    <div className="mb-1 text-muted">2019/02/12</div>
                                    <p className="card-text mb-auto">This is a wider card with supporting text below as a natural lead-in to additional content.</p>
                                    <a href="#">Continue reading</a>
                                </div>
                                <img className="card-img-right flex-auto d-none d-md-block" alt="Thumbnail [200x250]" src={require('../../lib/img/blog3.jpg')} data-holder-rendered="true" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card flex-md-row mb-4 box-shadow h-md-250">
                                <div className="card-body d-flex flex-column align-items-start">
                                    <strong className="d-inline-block mb-2 text-success">Vietnam</strong>
                                    <h3 className="mb-0">
                                        <a className="text-dark" href="#">Post title</a>
                                    </h3>
                                    <div className="mb-1 text-muted">2019/02/12</div>
                                    <p className="card-text mb-auto">This is a wider card with supporting text below as a natural lead-in to additional content.</p>
                                    <a href="#">Continue reading</a>
                                </div>
                                <img className="card-img-right flex-auto d-none d-md-block" alt="Thumbnail [200x250]" src={require('../../lib/img/blog4.jpg')} data-holder-rendered="true" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withNamespaces()(NewEvent);