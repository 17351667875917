import React, { Component } from 'react';
import Navigation from '../header/navigation';
import Contact from '../home-page/Contact';
import Footer from '../footer/Footer';

class Organization extends Component {
    componentWillMount(){
        document.body.classList.remove("isHomePage");       
    }
    render() {
        return (
            <div className="full-screen-scroller">
                <Navigation/>
                <div id="breadcrumbs" style={{backgroundImage: "url(" + require('../../lib/img/bgtop-company-5.jpg') + ")"}}></div>
                <div id="breadcrumbs_box">
                    <div className="container clearfix text-right">
                        <i className="fas fa-home"></i> <a href="/">Trang chủ</a> <i className="fas fa-angle-double-right" style={{fontSize:"10px"}}></i> Sơ đồ tổ chức
                    </div>
                </div>
                <div className="container" style={{paddingTop: "40px"}}>
                    <h1 className="title-page">Sơ đồ tổ chức</h1>                    
                    <h5 className="sub-title-page"><i className="fas fa-quote-left"></i>Sơ đồ tổ chức Daedong Korea Ginseng Co., Ltd (Tính đến tháng 3 năm 2018)</h5>
                    <div className="row" style={{paddingTop: "40px"}}>
                        <div className="col-12 col-sm-6 text-center organ-div">
                            <h2 className="h2-title-page">Trụ sở chính / Nhà máy</h2>
                            586, đường Nambuk, Mukbuk-myeon, Geumsan-gun, Chungnam<br/>
                            Điện thoại: 041-753-8804
                        </div>
                        <div className="col-12 col-sm-6 text-center organ-div">
                            <h2 className="h2-title-page">Văn phòng Seoul</h2>
                            471, đường núi cao, Dongdaemun-gu, Seoul<br/>
                            Điện thoại: 02-964-8808
                        </div>
                    </div>
                </div>

                <img src={require('../../lib/img/so-do-to-chuc-Daedong-2018.png')} className="img-fluid mx-auto d-block" alt="Sơ đồ tổ chức Daedong" style={{marginTop:"40px"}}/>

                <Contact/>

                <Footer/>
                
            </div>
        );
    }
}

export default Organization;