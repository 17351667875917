import React, { Component } from 'react';
import Navigation from '../header/navigation';
import Contact from '../home-page/Contact';
import Footer from '../footer/Footer';
import $ from 'jquery';

class FusionGinssen extends Component {
    componentDidMount(){
        document.body.classList.add("isFusionGinssen");  
    }
    render() {
        $('body').removeAttr( "class" );
        return (
            <div className="full-screen-scroller">
                <Navigation/>
                <div id="breadcrumbs" style={{backgroundImage: "url(" + require('../../lib/img/bgtop-system-02.jpg') + ")"}}></div>
                <div id="breadcrumbs_box">
                    <div className="container clearfix text-right">
                        <i className="fas fa-home"></i> <a href="/">Trang chủ</a> <i className="fas fa-angle-double-right" style={{fontSize:"10px"}}></i> Công nghệ sản xuất Fusion
                    </div>
                </div>
                <div className="container" style={{paddingTop: "40px"}}>
                    <h1 className="title-page">Công nghệ sản xuất tập trung Fusion mới</h1>
                    <h5 className="sub-title-page"><i className="fas fa-quote-left"></i>Daedong Korea Ginseng Co.,Ltd sử dụng Công nghệ sản xuất tập trung Fusion mới, nhằm đưa ra các dòng sản phẩm chất lượng cao, giữ nguyên thành phần vốn có của nhân sâm ở nhiệt độ thấp, thời gian ngắn để cho ra hai dòng sản phẩm chính của Daedong là: Hắc sâm, và Hồng sâm Hàn quốc</h5>
                    <img src={require('../../lib/img/FusionGinssen-01.jpg')} className="img-fluid mx-auto d-block" alt="Fusion Ginssen"/>
                    <div className="row" style={{marginTop:"25px"}}>
                        <h2 className="h2-title-page col-12">| Hệ thống sản xuất tập trung Fusion mới của Daedong</h2>
                        <p className="text-justify col-12">
                            Hệ thống sản xuất tập trung Fusion mới là một công nghệ hàng đầu trong lĩnh vực chiết xuất và cô đặc sản phẩm có nguồn gốc tự nhiên, hệ thống tập trung (Falling Film) kết hợp với cơ chế kỹ thuật mới với 3 công nghệ mới, bao gồm: Tập trung làm giảm nồng độ, Giữ nguyên các hợp chất dinh dưỡng vốn có, Tuần hoàn tự nhiên các hợp chất.
                        </p>
                        <div className="col-12 col-sm-4 text-center">
                            <img src={require('../../lib/img/fusion-ginssen-icon-01.png')} className="img-fluid mx-auto d-block" alt="Fusion Ginssen"/>
                            <h5>| Tập trung làm giảm nồng độ |</h5>
                        </div>
                        <div className="col-12 col-sm-4 text-center">
                            <img src={require('../../lib/img/fusion-ginssen-icon-02.png')} className="img-fluid mx-auto d-block" alt="Fusion Ginssen"/>
                            <h5>| Giữ nguyên các hợp chất dinh dưỡng vốn có |</h5>
                        </div>
                        <div className="col-12 col-sm-4 text-center">
                            <img src={require('../../lib/img/fusion-ginssen-icon-03.png')} className="img-fluid mx-auto d-block" alt="Fusion Ginssen"/>
                            <h5>| Tuần hoàn tự nhiên các hợp chất |</h5>
                        </div>
                        <p className="text-center col-12">
                            Thông thường, trong quá trình sản xuất tinh chất hồng sâm tự nhiên, người ta thường thêm vào chất khử bọt bong bóng bị gây ra bởi các thành phần saponin. Tuy nhiên, với Hệ thống sản xuất tập trung Fusion mới của Duham đã cho phép thực hiện sản xuất cao Sâm tư nhiên mà không cần sử dụng chất khử bọt và bao gồm hệ thống có khả năng sản xuất Nhiệt độ thấp nồng độ cao trong thời gian ngắn.
                        </p>
                        <h2 className="h2-title-page col-12">| Đặc trưng riêng của hệ thống sản xuất tập trung Fusion của Daedong</h2>                        
                        <div className="col-12 col-sm-3 text-center">
                            <img src={require('../../lib/img/fusion-ginssen-icon-04.png')} className="img-fluid mx-auto d-block" alt="Fusion Ginssen"/>
                            <h5>Thân thiện với hệ sinh thái</h5>
                        </div>
                        <div className="col-12 col-sm-3 text-center">
                            <img src={require('../../lib/img/fusion-ginssen-icon-05.png')} className="img-fluid mx-auto d-block" alt="Fusion Ginssen"/>
                            <h5>Không sử dụng chất tạo bọt</h5>
                        </div>
                        <div className="col-12 col-sm-3 text-center">
                            <img src={require('../../lib/img/fusion-ginssen-icon-06.png')} className="img-fluid mx-auto d-block" alt="Fusion Ginssen"/>
                            <h5>Duy trì hương vị ban đầu của sản phẩm</h5>
                        </div>
                        <div className="col-12 col-sm-3 text-center">
                            <img src={require('../../lib/img/fusion-ginssen-icon-07.png')} className="img-fluid mx-auto d-block" alt="Fusion Ginssen"/>
                            <h5>Cung cấp nguồn nguyên liệu tốt nhất</h5>
                        </div>
                        <div className="col-12 text-justify">
                            <h5>Thân thiện với hệ sinh thái:</h5>
                            <p>
                                Cho phép sản xuất thân thiện với môi trường bằng cách sản xuất sản phẩm ở nhiệt độ thấp trong thời gian ngắn.
                            </p>
                        </div>
                        <div className="col-12 text-justify">
                            <h5>Không sử dụng chất tạo bọt:</h5>
                            <p>
                                Không sử dụng bất kỳ hợp chất hóa học nào nhằm loại bỏ bọt khí tự nhiên được tạo ra bởi thành phần saponin có trong nhân sâm
                            </p>
                        </div>
                        <div className="col-12 text-justify">
                            <h5>Duy trì hương vị ban đầu của sản phẩm:</h5>
                            <p>
                                Trong qua trình sản xuất, Hồng sâm – Hắc sâm đều giữ được hương vị ban đầu và mùi thơm vốn có.
                            </p>
                        </div>
                        <div className="col-12 text-justify">
                            <h5>Cung cấp nguồn nguyên liệu tốt nhất:</h5>
                            <p>
                                Có khả năng cung cấp nguồn nguyên liệu tốt nhất nhằm lưu giữ tối đa tác dụng của các thành phần dược lý của Hồng sâm và Hắc sâm, như: Ginsenosides, các polysaccharide có tính axit, và các protein hòa tan trong nước,…
                            </p>
                        </div>
                        <div className="col-12 video-12" style={{marginTop:"40px"}}>
                            <iframe className="mx-auto d-block" src="https://www.youtube.com/embed/Me9xdMNhxkQ" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
                <Contact/>

                <Footer/>
            </div>
        );
    }
}

export default FusionGinssen;