import React, {Component} from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import HomePage from './components/HomePage';
import Stores from './components/Stores';
import { createBrowserHistory } from 'history';
import AboutGinssen from './components/pages/AboutGinssen';
import ElvanDaedong from './components/pages/ElvanDaedong';
import FusionGinssen from './components/pages/FusionGinssen';
import CleanSystemGinssen from './components/pages/CleanSystemGinssen';
import FacilityDetail from './components/pages/FacilityDetail';
import NotFound from './components/NotFound';
import OEM_ODM from './components/pages/OEM.ODM';
import QulityControl from './components/pages/QulityControl';
import PatentCertification from './components/pages/PatentCertification';
import RD_Center from './components/pages/RD.Center';
import DaedongStory from './components/pages/DaedongStory';
import CeoDaedong from './components/pages/CeoDaedong';
import Vision from './components/pages/Vision';
import HistoryCompany from './components/pages/HistoryCompany';
import Organization from './components/pages/Organization';
import LocationMap from './components/pages/LocationMap';
import ProductsList from './components/pages/ProductsList';
import ProdcutDetail from './components/pages/ProdcutDetail';
import ScrollToTop from './ScrollToTop';


const history = createBrowserHistory();

class App extends Component {
  
  render() {
    return (
      <div className="rootWrap">
        <Router history={history}>
          <ScrollToTop>
            <Switch>
            
              {/* Giới thiệu menu */}
              <Route exact strict path='/' component={HomePage} />
              <Route exact strict path='/daedong-strory' component={DaedongStory} />
              {/* Năng lực menu */}
              <Route exact strict path='/about-ginssen' component={AboutGinssen} />
              <Route exact strict path='/elvan-ginssen' component={ElvanDaedong} />
              <Route exact strict path='/fusion-ginssen' component={FusionGinssen} />
              <Route exact strict path='/clean-ginssen' component={CleanSystemGinssen} />
              <Route exact strict path='/facility' component={FacilityDetail} />
              <Route exact strict path='/oem-odm' component={OEM_ODM} />            
              <Route exact strict path='/quality-control' component={QulityControl} />
              <Route exact strict path='/patent-certification' component={PatentCertification} />
              <Route exact strict path='/r-d-center' component={RD_Center} />
              <Route exact strict path='/ceo-daedong' component={CeoDaedong} />
              <Route exact strict path='/vision' component={Vision} />
              <Route exact strict path='/history-company' component={HistoryCompany} />
              <Route exact strict path='/organization' component={Organization} /> 
              <Route exact strict path='/location' component={LocationMap} />
              <Route exact strict path='/list-products' component={ProductsList} /> 
              <Route exact strict path='/product' component={ProdcutDetail} /> 
            
              
              {/* List store menu */}
              <Route exact strict path='/list-stores' component={Stores} />        
              <Route exact strict path='*' component={NotFound} />                                                                      
            </Switch>
          </ScrollToTop>
        </Router>          
      </div>
    );
  }
}

export default App;
