import React, { Component } from 'react';
import Navigation from '../header/navigation';
import Contact from '../home-page/Contact';
import Footer from '../footer/Footer';
import $ from 'jquery';

class HistoryCompany extends Component {
    componentDidMount(){
        document.body.classList.add("isHistoryCompany");  
    }
    componentWillMount(){                  
        $("a").on('click', function(event) {

            // Make sure this.hash has a value before overriding default behavior
            if (this.hash !== "") {
              // Prevent default anchor click behavior
              event.preventDefault();
        
              // Store hash
              var hash = this.hash;
        
              // Using jQuery's animate() method to add smooth page scroll
              // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
              $('html, body').animate({
                scrollTop: $(hash).offset().top
              }, 800, function(){
           
                // Add hash (#) to URL when done scrolling (default click behavior)
                window.location.hash = hash;
              });
            } // End if
          });
    }

    render() {
        $('body').removeAttr( "class" );
        return (
            <div className="full-screen-scroller">
                <Navigation/>
                <div id="breadcrumbs" style={{backgroundImage: "url(" + require('../../lib/img/bgtop-company-5.jpg') + ")"}}></div>
                <div id="breadcrumbs_box">
                    <div className="container clearfix text-right">
                        <i className="fas fa-home"></i> <a href="/">Trang chủ</a> <i className="fas fa-angle-double-right" style={{fontSize:"10px"}}></i> Lịch sử
                    </div>
                </div>
                <div className="container" style={{paddingTop: "40px"}}>
                    <h1 className="title-page">Lịch sử</h1>                    
                    <h5 className="sub-title-page"><i className="fas fa-quote-left"></i>Hành trình đổi mới của Daedong Korea Ginseng Co., Ltd.</h5>
                    <img src={require('../../lib/img/history-top.jpg')} className="img-fluid mx-auto d-block" alt="History Daedong"/>
                    <h2 className="h2-title-page" style={{margin: "20px 0 10px"}}>| Quá trình hình thành</h2>
                    <div className="row border-year">
                        <div className="col-3">
                            <a href="#2005">2002 ~ 2005</a>
                        </div>
                        <div className="col-3">
                            <a href="#2009">2006 ~ 2009</a>
                        </div>
                        <div className="col-3">
                            <a href="#2013">2010 ~ 2013</a>
                        </div>
                        <div className="col-3">
                            <a href="#2017">2014 ~ 2017</a>
                        </div>
                    </div>
                    <img src={require('../../lib/img/history-01.png')} className="img-fluid mx-auto d-block" alt="History Daedong"/>
                    <div className="row">
                        <h3 className="col-12 text-center h2-title-page" style={{margin: "40px 0"}} id="2017">2017</h3>
                        <div className="col-6 text-right history-right">
                            <p>Tháng 12: Được phê duyệt thành công Giấy phép đảm bảo an toàn vệ sinh thực phẩm tại Trung Quốc, cho 6 loại sản phẩm Hắc sâm Daedong</p>
                            <p>Tháng 12: Được Chính phủ trao tặng huân chương Công nghiệp Đồng Tháp, vì đóng góp doanh nghiệp nhằm Thúc đẩy hỗ trợ việc làm.</p>
                            <p>Tháng 11: Đạt chứng nhận Sản phẩm đạt chuẩn HACCP (trực thuộc Cơ quan chứng nhận quản lý An toàn thực phẩm Hàn quốc).</p>
                            <p>Tháng 9: Đăng ký thành công nhãn hiệu độc quyền và tham dự Hội chợ Geumsan International Expo “금산 세계인삼엑스포”</p>
                            <p>Tháng 8: Dòng sản phẩm hắc sâm của Daedong Korea Ginseng được bình chọn là “Sản phẩm văn hóa xuất sắc 2017” tại Sự kiện K-Ribbon Selection 2017 (trực thuộc Bộ Văn hóa, Thể thao và Du lịch Hàn quốc)</p>
                            <p>Tháng 7: Đăng ký hợp tác kinh doanh và nghiên cứu thảo dược thành công với Trung tâm Nghiên cứu và khuyến nông Chungnam (trực thuộc Viện Nghiên cứu thảo dược Nhân sâm Hàn quốc)</p>
                            <p>Tháng 3: Phát triển Showroom Giới thiệu sản phẩm tại Thượng Hải: Trung tâm thương mại Mandal (上海宝龙商场-嘉定店)</p>
                            <p>Tháng 3:  Phát triển Showroom Giới thiệu sản phẩm tại Thượng Hải: Trung tâm thượng mại BeoRyeong (上海宝龙商场-嘉定店)</p>
                            <p>Tháng 2: Đạt chứng nhận HALAL (할랄)</p>
                            <p>Tháng 2: Kinh doanh thành công 12 mặt hàng Hồng sâm Hàn quốc tại thị trường Trung Quốc</p>
                            <p>Tháng 1: Ra mắt nhãn hiệu mới - “진쎈(GINSSEN)” – Thương hiệu Hắc sâm Daedong</p>
                        </div>
                        <div className="col-6">
                            <img src={require('../../lib/img/history-02.png')} className="img-fluid mx-auto d-block" alt="History Daedong"/>
                        </div>
                        <div className="col-6 text-right history-right">
                            <h3 className="h2-title-page" style={{margin: "40px 0 10px"}} id="2016">2016</h3>
                            <p>Tháng 9: Thành lập chi nhánh Daedong tại Trung Quốc</p>
                            <p>Tháng 8: Thành lập Hội sở hữu cổ phẩn cho Nhân viên lao động của Daedong</p>
                            <p>Tháng 4: Tham dự Workshop Trung Quốc tại Seoul</p>
                        </div>
                        <div className="col-6">&nbsp;</div>
                        <div className="col-6 text-right history-right">
                            &nbsp;
                        </div>
                        <div className="col-6">
                            <h3 className="h2-title-page" style={{margin: "40px 0 10px"}} id="2015">2015</h3>
                            <p>Tháng 8: Đóng góp cổ phần Tập đoàn LG – Healthy Activity</p>
                            <p>Tháng 8: Tham gia hội chợ thực phẩm quốc tế Đài Bắc tại Đài Loan</p>
                            <p>Tháng 8/2015: Tham gia hội chợ triển lãm Milan</p>
                            <p>Tháng 7: Hoàn tất chứng chỉ GMP tại Nhà máy thứ 2 (về SD, thiết bị lên men, dây chuyền)</p>
                            <p>Tháng 4: Tham gia sự kiện Tư vấn xuất khẩu tại Tokyo, Nhật Bản.</p>
                        </div>
                        <div className="col-6 text-right history-right">
                            <h3 className="h2-title-page" style={{margin: "40px 0 10px"}} id="2014">2014</h3>
                            <p>Tháng 12: Tham dự chuỗi cửa hàng miễn thuế tại sân bay CheongJu</p>
                            <p>Tháng 11: Tham gia Hội chợ Cao Hùng tại Đài Loan</p>
                            <p>Tháng 10: Tham gia hội chợ xuất nhập khẩu Quảng Châu</p>
                            <p>Tháng 9: Tham gia hội chợ thực phẩm quốc tế tại Istanbul, Thổ Nhĩ Kỳ</p>
                            <p>Tháng 7: Tham gia Hội chợ K-Food tại Thượng Hải, Trung Quốc</p>
                            <p>Tháng 6: Thỏa thuận kinh doanh thành công và hoàn thiện hợp đồng đặt hàng sản phẩm PB với Cơ quan xúc tiến kinh doanh bưu chính (Post Office Shopping)</p>
                            <p>Tháng 5: Tham gia hội chợ ẩm thực quốc tế Thái Lan</p>
                            <p>Tháng 5: Tham gia hội chợ thực phẩm quốc tế Philippines</p>
                        </div>
                        <div className="col-6">
                            &nbsp;
                        </div>
                        <div className="col-6 text-right history-right">
                            &nbsp;
                        </div>
                        <div className="col-6">                    
                            <h3 className="h2-title-page" style={{margin: "40px 0 10px"}} id="2013">2013</h3>
                            <h4>Góp mặt, trở thành cổ phiếu được liệt kê trên Sàn giao dịch Conex Hàn quốc</h4>
                            <p>Tháng 12: Được liệt kê trên thị trường Conex của Sàn giao dịch Hàn Quốc</p>
                            <p>Tháng 11: Tham gia World Tour Nhân sâm Hàn Quốc tại Việt Nam</p>
                            <p>Tháng 10: Tham gia triển lãm chữ thập đỏ (trực thuộc chính phủ Hàn quốc)</p>
                            <p>Tháng 9: Có mặt tại gian hàng tiêu biểu cỡ nhỏ tại Trung tâm Daejeon Station (대전역 매장)</p>
                            <p>Tháng 9: Tham gia hội chợ K-Food tại Hà Nội</p>
                        </div>
                        <div className="col-6 text-right history-right">                            
                            <h3 className="h2-title-page" style={{margin: "40px 0 10px"}} id="2012">2012</h3>
                            <h4>Tham dự Hội chợ ẩm thực Dubai & 2 triển lãm lớn ở nước ngoài</h4>
                            <p>Tháng 12: Tham gia triển lãm đặc biệt về nhân sâm Geumsan BEXCO tại Busan </p>
                            <p>Tháng 6: Ra mắt thương hiệu trung tâm mua sắm Shopping Mall "Duham" (더함)</p>
                            <p>Tháng 3: Được công nhận là doanh nghiệp liên doanh (từ Quỹ bảo lãnh công nghệ Hàn quốc)</p>
                        </div>
                        <div className="col-6">                    
                            &nbsp;
                        </div>
                        <div className="col-6 text-right history-right">                            
                            &nbsp;
                        </div>
                        <div className="col-6">                                                
                            <h3 className="h2-title-page" style={{margin: "40px 0 10px"}} id="2011">2011</h3>
                            <h4>Tham dự Hội chợ thực phẩm Quốc tế Ấn Độ và 4 triển lãm - sự kiện lớn tại nước ngoài</h4>
                            <p>Tháng 12: Được chỉ định và công nhận là cơ sở tuân thủ theo tiêu chuẩn GMP quốc gia</p>
                            <p>Tháng 12: Thành lập nhà máy chiết xuất cô đặc mới – đạt tiêu chuẩn</p>
                            <p>Tháng 11: Được công nhận là Nhà máy đạt CLEAN - SẠCH (từ Bộ Việc làm và Lao động Hàn quốc),  số 20 653</p>
                            <p>Tháng 10: Hoàn thành nhà máy và nhà kho mới đạt tiêu chuẩn GMP</p>
                            <p>Tháng 7: Hoàn thành ký túc xá mới</p>
                            <p>Tháng 5: Tái phê duyệt của INNOBIZ R7055-4453 với tư cách là một doanh nghiệp công nghệ mới</p>
                            <p>Tháng 2: Bắt đầu xuất khẩu sang Thổ Nhĩ Kỳ</p>
                            <p>Tháng 1: Được công nhận là doanh nghiệp liên doanh, (Số 2012200999).</p>
                        </div>
                        <div className="col-6 text-right history-right">                                                        
                            <h3 className="h2-title-page" style={{margin: "40px 0 10px"}} id="2010">2010</h3>
                            <h4>Tham dự Hội chợ Thực phẩm New York và 2 triển lãm – sự kiện lớn tại nước ngoài</h4>
                            <p>Tháng 10: Doanh nghiệp được Viên nghiên cứu công nhận (Số 20074304)</p>
                            <p>Tháng 9: Được phê duyệt thành công sản xuất và phân phối (dòng sản phẩm Cao hồng sâm và 11 dòng sản phẩm khác)</p>
                            <p>Tháng 7: Được chỉ định chứng nhận chất lượng G-mark Geumsan</p>
                        </div>
                        <div className="col-6">                                                
                            &nbsp;
                        </div>
                        <div className="col-6 text-right history-right">                                                        
                            &nbsp;
                        </div>
                        <div className="col-6">                                                                            
                            <h3 className="h2-title-page" style={{margin: "40px 0 10px"}} id="2009">2009</h3>
                            <h4>Tham dự Hội chợ thực phẩm Hồng Kông (HOFEX) và 6 triển lãm lớn ở nước ngoài</h4>
                            <p>Tham gia các nhóm phát triển thị trường toàn quốc</p>                            
                        </div>
                        <div className="col-6 text-right history-right">                                                        
                            <h3 className="h2-title-page" style={{margin: "40px 0 10px"}} id="2008">2008</h3>
                            <h4>Tham dự Hội chợ xuất khẩu châu Âu</h4>
                            <p>Tháng 10: Đạt chứng nhận GAP (Chỉ định cơ sở quản lý nông nghiệp tiêu chuẩn Hàn quốc)</p>
                            <p>Ngày 15 tháng 9: Tham dự Giải đấu Doanh nhân Chungcheongnam-do, xuất sắc nhận "Giải thưởng Công ty xuất sắc" (09.29)</p>
                            <p>Tháng 9: Được phê duyệt thành công quyền sử dụng nhãn hiệu số 08-001 trực thuộc tỉnh Geumsan.</p>
                            <p>Tháng 7: Hiển thị thành công đăng ký địa lý số 48 </p>
                            <p>Tháng 6: Được Văn phòng chi nhánh Chungcheongnam-do chỉ định là Doanh nghiệp đạt Chất lượng sản phẩm nông nghiệp và chăn nuôi (품질추천 농축산물)                       </p>
                        </div>
                        <div className="col-6">                                                                            
                            &nbsp;
                        </div>
                        <div className="col-6 text-right history-right">                                                        
                            &nbsp;
                        </div>
                        <div className="col-6">                                                                                                        
                            <h3 className="h2-title-page" style={{margin: "40px 0 10px"}} id="2007">2007</h3>
                            <h4>Kinh doanh và phát triển thị trường Nông sản Hoa Kỳ và tham dự 2 triển lãm trong và ngoài nước, đồng thời tham dự các  nhóm tiên phong phát triển thị trường.</h4>
                            <p>Tháng 12: Được công nhận là doanh nghiệp có nhiều đổi mới và phát triển công nghệ (INNO-BIZ)</p>
                            <p>Tháng 11: Được Bộ trưởng Bộ Nông – Lâm nghiệp Hàn quốc chứng nhận Doanh nghiêp Nông Nghiệp số 60578</p>
                            <p>Tháng 11: Đạt chứng nhận ISO 22000: 2005</p>
                            <p>Tháng 10: Đạt chứng nhận công ty liên doanh</p>
                            <p>Tháng 8: Thành lập Viện nghiên cứu trực thuộc công ty Daedong</p>
                            <p>Tháng 7/2007: Chứng nhận doanh nghiệp vừa và nhỏ tiềm năng vùng Chungcheongnam-do</p>                            
                        </div>
                        <div className="col-6 text-right history-right">                                                        
                            <h3 className="h2-title-page" style={{margin: "40px 0 10px"}} id="2006">2006</h3>
                            <h4>Tham dự Triển lãm APTEKA 2006 tại Nga và 9 triển lãm – sự kiện nước ngoài</h4>                            
                            <p>Tháng 9: Đăng ký và tham gia Chủ sở hữu nhãn hiệu tại Triển lãm nhân sâm thế giới Geumsan (금산세계인삼엑스포)</p>
                        </div>
                        <div className="col-6">                                                                                                        
                            &nbsp;                            
                        </div>
                        <div className="col-6 text-right history-right">                                                        
                            &nbsp;
                        </div>
                        <div className="col-6">                                                                                                                                                                
                            <h3 className="h2-title-page" style={{margin: "40px 0 10px"}} id="2005">2005</h3>
                            <h4>Tham dự Triển lãm FCE PHARMA 2005 tại Brazil và 7 triển lãm – sự kiện nước ngoài, cùng các nhóm phát triển thị trường.</h4>                            
                            <p>Tháng 9: Đạt chứng nhận Hệ thống quản lý môi trường - 환경경영시스템 (KS A 14001: 2004 / ISO 14001: 2004)</p>
                        </div>
                        <div className="col-6 text-right history-right">                                                                                    
                            <h3 className="h2-title-page" style={{margin: "40px 0 10px"}} id="2004">2004</h3>
                            <h4>Tham dự Triển lãm dược liệu thô thế giới - CPHI world wide và 8 triển lãm – sự kiện nước ngoài.</h4>
                        </div>
                        <div className="col-6">                                                                                                                                                                
                            &nbsp;                                                        
                        </div>
                        <div className="col-6 text-right history-right">                                                                                                                
                            &nbsp;
                        </div>
                        <div className="col-6">                                                                                                                                                                                            
                            <h3 className="h2-title-page" style={{margin: "40px 0 10px"}} id="2003">2003</h3>
                            <p>Tháng 11: Được chọn giới thiệu là Doanh nghiệp điển hình bởi Hiệp hội Y học Hàn Quốc</p>
                            <p>Tháng 11: Được lựa chọn là đối tác học thuật trong ngành của Trung tâm nghiên cứu công nghiệp nhân sâm của Đại học Chung-Ang</p>
                            <p>Tháng 10 Bắt đầu xuất khẩu sang Mỹ và Hồng Kông</p>
                            <p>Chứng nhận hệ thống quản lý chất lượng tháng 6 (ISO 9001 - 2000)</p>
                            <p>Chứng nhận hệ thống quản lý an toàn thực phẩm tháng 6 (HACCP)</p>
                            <p>Tháng 5: Đăng ký thành công 15 trường hợp bao gồm: nhãn hiệu, đơn đăng ký nhãn hiệu</p>
                            <p>Tháng 2: Đổi tên công ty thành Daedong Korea Ginseng Co., Ltd.</p>
                        </div>
                        <div className="col-6 text-right history-right">                                                                                                                
                            <h3 className="h2-title-page" style={{margin: "40px 0 10px"}} id="2002">2002</h3>
                            <p>Tháng 12: Hoàn thành xây dựng nhà máy</p>
                            <p>Tháng 6: Tiến hành xây dựng nhà xưởng</p>
                            <p>Tháng 3:  Thành lập Tập đoàn Hồng sâm Daedong</p>
                        </div>
                        <div className="col-6">                                                                                                                                                                
                            &nbsp;                                                       
                        </div>
                    </div>
                </div>
                                   

                <Contact/>

                <Footer/>
                
            </div>
        );
    }
}

export default HistoryCompany;