import React, { Component } from 'react';
import Navigation from '../header/navigation';
import Contact from '../home-page/Contact';
import Footer from '../footer/Footer';
import $ from 'jquery';

class ElvanDaedong extends Component {
    componentDidMount(){
        document.body.classList.add("isElvanDaedong");  
    }
    render() {
        $('body').removeAttr( "class" );
        return (
            <div className="full-screen-scroller">
                <Navigation/>
                <div id="breadcrumbs" style={{backgroundImage: "url(" + require('../../lib/img/bgtop-system-01-1.jpg') + ")"}}></div>
                <div id="breadcrumbs_box">
                    <div className="container clearfix text-right">
                        <i className="fas fa-home"></i> <a href="/">Trang chủ</a> <i className="fas fa-angle-double-right" style={{fontSize:"10px"}}></i> Hệ thống sây khô Elvan
                    </div>
                </div>
                <div className="container" style={{paddingTop: "40px"}}>
                    <h1 className="title-page">Hệ thống sây khô Elvan mới hiện đại</h1>
                    <h5 className="sub-title-page"><i className="fas fa-quote-left"></i>Hệ thống sây khô mới hiện đại của Daedong Korea Ginseng Co.,Ltd đã giúp tăng tỷ lệ bảo quản, tăng và lưu giữ thành phần dinh dưỡng vốn có trong hồng sâm</h5>
                    <img src={require('../../lib/img/elvan-01.jpg')} className="img-fluid mx-auto d-block" alt="Elvan Ginssen"/>                    
                    <div className="row" style={{marginTop:"25px"}}>
                        <h2 className="h2-title-page col-12">| Hệ thống sấy khô bằng đá elven (맥반석) của Daedong Korea Ginseng Co.,Ltd</h2>
                        <p className="text-justify col-12">
                            Từ xa xưa, Hồng sâm và Hắc sâm đã được chế biên thông qua quy trình hấp sấy lặp đi lặp lại nhiều lần. Vào thời điểm hiện nay, Quy trình sấy khô cũ sẽ khiến cho chất lương sâm trong quá trình sấy khô bị tản mất nhiệt, gây lên sự giảm sút về mặt thành phần và chất lượng sản phẩm. Với việc sử dụng Hệ thống sấy khô bằng đá elven, các sản phẩm Hồng sâm của Daedong giúp lưu giữ “chất” vốn có trong hồng sâm và đem lại hiệu quả tốt hơn trong quá trình sản xuất các dòng sản phẩm chiết xuất.
                        </p>
                        <div className="col-12 col-sm-7">
                            <h2 className="h2-title-page">| Hiệu quả vượt bậc của Hệ thống sấy khô bằng đá elven (맥반석)</h2>
                            <p className="text-justify">
                                Đá elven (맥반석) thường có từ 3 đến 150 nghìn độ xốp trên 1 cm3, với diện tích bề mặt lớn, có khả năng hấp phụ và phân hủy các chất ô nhiễm và kim loại nặng, đồng thời có khả năng vô hiệu hoá chất độc, kháng khuẩn, thuốc trừ sâu và khử mùi rất mạnh.
                            </p>
                            <p className="text-justify">
                                Đá elven (맥반석) được biết đến rộng rãi vì khả năng có thể loại bỏ các vi khuẩn có trong nhân sâm. Khi đá được kết hợp với nhiệt độ đúng, giúp khử độc, đẩy nhanh hiệu quả thành phần có trong thực phẩm và hỗ trợ tăng hương vị bằng sự cộng hưởng, hấp thụ bởi bức xạ hồng ngoại xa.
                            </p>
                        </div>
                        <div className="col-12 col-sm-5">
                            <img src={require('../../lib/img/elvan-02.jpg')} className="img-fluid" alt="About Ginssen"/>
                        </div>
                        <div className="col-12" style={{marginTop:"30px"}}>
                            <img src={require('../../lib/img/elvan-03.jpg')} className="img-fluid mx-auto d-block" alt="Elvan Ginssen"/>                    
                        </div>
                    </div>                    
                </div>
                <Contact/>

                <Footer/>
            </div>
        );
    }
}

export default ElvanDaedong;