import React, { Component } from 'react';
import Navigation from '../header/navigation';
import Footer from '../footer/Footer'; 
import $ from 'jquery';

class ProdcutDetail extends Component {
    componentDidMount(){
        document.body.classList.add("isProductDetail");
    }
    render() {
        $('body').removeAttr( "class" );
        return (
            <div className="full-screen-scroller porudct-detail">
                <Navigation />
                <div id="breadcrumbs" style={{ backgroundImage: "url(" + require('../../lib/img/bgtop-product-red-ginseng.jpg') + ")" }}></div>
                <div id="breadcrumbs_box">
                    <div className="container clearfix text-right">
                        <i className="fas fa-home"></i> <a href="/">Trang chủ</a> <i className="fas fa-angle-double-right" style={{ fontSize: "10px" }}></i> Sản phẩm
                    </div>
                </div>
                <div className="container" style={{ paddingTop: "30px" }}>
                    <h1 className="title-page"><img className="icon-daedong" src={require('../../lib/img/favi-icon-Daeodong.png')} /> Cao hắc sâm nguyên chất 120gr</h1>
                    <div className="row">

                        <div className="col-md-4">                            
                            <img className="img-fluid mx-auto d-block" src={require('../../lib/img/cao-hac-sam-120gr-daedong.jpg')} alt="Card image cap" />
                        </div>

                        <div className="col-md-4 text-justify">
                            <h3 className="my-3 des-short">100% từ hắc sâm cao cấp nguyên chất</h3>
                            <div className="wrap-price row">
                                <div className="col-12 col-lg-3">Giá bán: </div>
                                <div className="col-12 col-lg-9"><strong className="main-price">3,550,000 VNĐ</strong>&nbsp;&nbsp;</div>
                                {/* <div className="col-12 col-lg-9"><strong className="main-price has-sale">3,550,000 VNĐ</strong>&nbsp;&nbsp;<strong className="sale-price">2,695,000 VNĐ</strong></div>                                
                                <div className="discount_rate_detail rate30">30<span>%</span></div>                                 */}
                            </div>                            
                            <p>Hỗ trợ dành riêng cho người tiểu đường, huyết áp và mỡ máu cao</p>
                            <h3 className="my-3">Chỉ số sản phẩm</h3>
                            <ul className="ulChiSo">
                                <li>Sản xuất: Daedong Korea Ginseng</li>
                                <li>Nhãn hiệu: GINSSEN</li>
                                <li>Mã vạch: 8809118595840</li>
                                <li>Quy cách: Hộp 1 lọ 120 gram (đi kèm thìa định lượng)</li>
                                <li>Số xác nhận công bố: 29895/2017/ATTP-XNCB</li>
                            </ul>                            
                            <a href="#" className="icon-social-media"><i className="fab fa-facebook"></i></a>
                            <a href="#" className="icon-social-media"><i className="fab fa-instagram"></i></a>
                            <a href="#" className="icon-social-media"><i className="fab fa-linkedin-in"></i></a>
                            <a href="#" className="icon-social-media"><i className="fab fa-youtube"></i></a>
                        </div>
                        <div className="col-md-4 mini-cart-detail-product">
                            <div className="card shopping-cart">
                                <div className="card-header bg-danger text-light">
                                    <i className="fa fa-shopping-cart" aria-hidden="true"></i> Mua hàng nhanh!
                                </div>
                                <div className="card-body row align-items-center">                   
                                    <div className="col-7">
                                        <small>Cao hắc sâm nguyên chất 120gr</small>
                                    </div>
                                    <div className="col-2 quantity">                                                                                                   
                                        <input type="number" step="1" max="99" min="1" defaultValue="1" title="Số lượng" className="qty" autoFocus size="2"/>                                                
                                    </div>
                                    <div className="col-3 text-center">
                                        <small>3,550,000</small>
                                        <br/>
                                        <small>(VNĐ)</small>
                                    </div>                                                                                                                                                                                                                    
                                    <hr/>                                                                                
                                </div>
                                <div className="card-footer">
                                    <div className="row">
                                        <div className="col-5">
                                            <button className="btn btn-success" type="button" data-toggle="modal" data-target="#fastCartModal">Đặt hàng</button>
                                        </div>                                        
                                        <div className="text-right col-7" style={{lineHeight:"35px", fontSize:"14px"}} >                                                    
                                                Tổng tiền: <b>3,550,000 VNĐ</b>                                                                                
                                        </div>             
                                    </div>                                                                   
                                </div>
                            </div>                            
                        </div>
                    </div>                   

                    <div id="accordion" className="row" style={{margin:'40px 0'}}>
                        <div className="card">
                            <div className="card-header d-flex justify-content-center">
                                <a className="btn btn-outline-danger collapsed card-link" data-toggle="collapse" href="#collapseOne">
                                    Thông tin sản phẩm
                                </a>
                                <a className="btn btn-outline-danger card-link" data-toggle="collapse" href="#collapseTwo">
                                    Sản phẩm liên quan
                                </a>
                                <a className="btn btn-outline-danger collapsed card-link" data-toggle="collapse" href="#collapseThree">
                                    Chính sách mua hàng / Đổi trả
                                </a>
                                <a className="btn btn-outline-danger collapsed card-link" data-toggle="collapse" href="#reviewSP">
                                    Review Sản Phẩm
                                </a>
                                <a className="btn btn-outline-danger collapsed card-link" data-toggle="collapse" href="#LHmuaHang">
                                    Liên hệ mua hàng
                                </a>
                            </div>
                            <div id="collapseOne" className="collapse" data-parent="#accordion">
                                <div className="card-body">
                                    <p>
                                        <strong>Thành phần:</strong> Chiết xuất hắc sâm Hàn Quốc cô đặc (thành phần rắn 60%, hàm lượng ginsenoside Rg1, Rb1, Rg3 7mg/g) chiếm 100%. Tỉ lệ phối trộn rễ chính chiếm 70%, rễ phụ chiếm 30%
                                    </p>

                                    <p>
                                        <strong>Hướng dẫn sử dụng:</strong> Mỗi ngày uống 3 lần, mỗi lần 1 gram. Sử dụng thìa đi kèm mỗi lần pha 1 thìa với khoảng 80ml nước ấm.
                                    </p>

                                    <p>
                                        <strong>Lưu ý:</strong> Đối với người huyết áp thấp hoặc huyết áp không ổn định, nên uống liều lượng nhỏ và dùng sau khi ăn no. Người huyết áp cao và tiểu đường uống cách xa thời gian uống thuốc huyết áp/tiểu đường, phòng ngừa ung thư. Phụ nữ có thai và đang cho con bú không nên sử dụng.
                                    </p>

                                    <em>Sản phẩm không phải là thuốc, không thể thay thế thuốc chữa bện</em>                                    
                                    <br/>
                                    <br/>
                                    <img className="img-fluid mx-auto d-block" src={require('../../lib/img/product/cao-hac-sam-1.jpg')} alt="Card image cap" />
                                    <img className="img-fluid mx-auto d-block" src={require('../../lib/img/product/cao-hac-sam-2.jpg')} alt="Card image cap" />
                                    <img className="img-fluid mx-auto d-block" src={require('../../lib/img/product/cao-hac-sam-3.jpg')} alt="Card image cap" />
                                    <img className="img-fluid mx-auto d-block" src={require('../../lib/img/product/cao-hac-sam-4.jpg')} alt="Card image cap" />
                                    <img className="img-fluid mx-auto d-block" src={require('../../lib/img/product/cao-hac-sam-5.jpg')} alt="Card image cap" />
                                    <img className="img-fluid mx-auto d-block" src={require('../../lib/img/product/cao-hac-sam-6.jpg')} alt="Card image cap" />
                                    <img className="img-fluid mx-auto d-block" src={require('../../lib/img/product/cao-hac-sam-7.jpg')} alt="Card image cap" />
                                    <img className="img-fluid mx-auto d-block" src={require('../../lib/img/product/cao-hac-sam-8.jpg')} alt="Card image cap" />
                                    <img className="img-fluid mx-auto d-block" src={require('../../lib/img/product/cao-hac-sam-9.jpg')} alt="Card image cap" />
                                    <img className="img-fluid mx-auto d-block" src={require('../../lib/img/product/cao-hac-sam-10.jpg')} alt="Card image cap"/>
                                    <img className="img-fluid mx-auto d-block" src={require('../../lib/img/product/cao-hac-sam-11.jpg')} alt="Card image cap" />
                                    <img className="img-fluid mx-auto d-block" src={require('../../lib/img/product/cao-hac-sam-12.jpg')} alt="Card image cap" />
                                    <img className="img-fluid mx-auto d-block" src={require('../../lib/img/product/cao-hac-sam-13.jpg')} alt="Card image cap" />
                                </div>
                            </div>
                        </div>
                        <div className="card">      
                            <div id="collapseTwo" className="collapse show" data-parent="#accordion">
                            <div className="card-body row">                                
                                <div className="col-md-3 col-6">
                                    <a href="#">
                                        <img className="img-fluid" src="http://placehold.it/500x300" alt=""/>
                                    </a>
                                </div>

                                <div className="col-md-3 col-6">
                                    <a href="#">
                                        <img className="img-fluid" src="http://placehold.it/500x300" alt=""/>
                                    </a>
                                </div>

                                <div className="col-md-3 col-6">
                                    <a href="#">
                                        <img className="img-fluid" src="http://placehold.it/500x300" alt=""/>
                                    </a>
                                </div>

                                <div className="col-md-3 col-6">
                                    <a href="#">
                                        <img className="img-fluid" src="http://placehold.it/500x300" alt=""/>
                                    </a>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="card">      
                            <div id="collapseThree" className="collapse" data-parent="#accordion">
                            <div className="card-body">
                                - Chính sách giao hàng.
                                - Chính sách đổi trả hàng.
                            </div>
                            </div>
                        </div>
                        <div className="card">      
                            <div id="reviewSP" className="collapse" data-parent="#accordion">
                            <div className="card-body">
                                Review...
                            </div>
                            </div>
                        </div>
                        <div className="card">      
                            <div id="LHmuaHang" className="collapse" data-parent="#accordion">
                            <div className="card-body">
                                Liên hệ mua hàng!
                            </div>
                            </div>
                        </div>
                    </div>  
                    {/* Modal Fast Cart */}
                    <div className="modal fade" id="fastCartModal" tabIndex="-1" role="dialog" aria-labelledby="fastCartModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                            <div className="modal-header bg-light">
                                <h5 className="modal-title" id="fastCartModalLabel"><i className="fa fa-shopping-cart" aria-hidden="true"></i> Mua hàng nhanh!</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-3 d-flex align-items-center text-center">
                                            <img className="img-fluid mx-auto d-block" src={require('../../lib/img/tinh-chat-hac-sam-10ml.jpg')} alt="Card image cap" />
                                        </div>
                                        <div className="col-4 d-flex align-items-center text-center">
                                            Cao hắc sâm nguyên chất 120gr
                                        </div>
                                        <div className="col-2 d-flex align-items-center text-center">
                                            1 (Hộp)
                                        </div>
                                        <div className="col-3 d-flex align-items-center text-center">
                                            2,695,000 VNĐ
                                        </div>

                                    </div>
                                </div>
                                <form>
                                    <div className="input-group input-group-sm mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="refHoTenLabel">Họ tên:</span>
                                        </div>
                                        <input type="text" className="form-control" aria-label="refHoTen" aria-describedby="refHoTenLabel"/>
                                    </div>                                    
                                    <div className="input-group input-group-sm mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="refTellLabel">Điện thoại:</span>
                                        </div>
                                        <input type="tell" className="form-control" aria-label="refTell" aria-describedby="refTellLabel"/>
                                    </div>
                                    <div className="input-group input-group-sm mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="refEmailLabel">Email:</span>
                                        </div>
                                        <input type="email" className="form-control" aria-label="refEmail" aria-describedby="refEmailLabel"/>
                                    </div>
                                
                                </form>
                            </div>
                            <div className="modal-footer bg-light">
                                <button type="button" className="btn btn-info" data-dismiss="modal"><i className="fas fa-trash-alt"></i> Hủy</button>
                                <button type="button" className="btn btn-danger"><i className="fa fa-shopping-cart" aria-hidden="true"></i> Đặt hàng</button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <Footer/>                
            </div>
        );
    }
}

export default ProdcutDetail;