import React, { Component } from 'react';
import i18n from "i18next";
import { withNamespaces  } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';

class Navigation extends Component {
    componentWillMount() {
        i18n.addResourceBundle('vn', 'btn-language', {
            key: 'Ngôn ngữ',
        });
        i18n.addResourceBundle('en', 'btn-language', {
            key: 'Language',
        });
    }

    render() {    
        const { t, i18n } = this.props;

        const changeLanguage = (lng) => {
            i18n.changeLanguage(lng);
        };
        return (
            <header style={{ position: 'absolute', zIndex: 999, width: '100%', top: 0 }}>
                <nav className="navbar navbar-expand-md navbar-light bg-light fixed-top">
                    <Link className="navbar-brand lg-moblie" to="/"><img className="img-fluid logo" src={require('../../lib/img/logo.png')} /></Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-md-center" id="navbarCollapse" style={{ lineHeight: '40px' }}>
                        <ul className="navbar-nav">
                            <li><Link className="navbar-brand lg-desktop" to="/"><img className="img-fluid logo" src={require('../../lib/img/logo.png')} /></Link></li>
                            <li className="nav-item active dropdown">
                                <Link className="nav-link" role="button" id="dropdownMenuLink1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" to="/">{t('menu.company')} <span className="sr-only">(current)</span></Link>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink1">
                                    <a className="dropdown-item" href="/daedong-strory">{t('sub-menu.daedong-strory')}</a>
                                    <a className="dropdown-item" href="/ceo-daedong">{t('sub-menu.ceo-daedong')}</a>
                                    <a className="dropdown-item" href="/vision">{t('sub-menu.vision')}</a>
                                    <a className="dropdown-item" href="/history-company">{t('sub-menu.history-company')}</a>
                                    <a className="dropdown-item" href="/organization">{t('sub-menu.organization')}</a>
                                    <a className="dropdown-item" href="/location">{t('sub-menu.location-map')}</a>                                 
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className="nav-link" role="button" id="dropdownMenuLink2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" to="#">{t('menu.business')}</Link>                                
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink2">
                                    <a className="dropdown-item" href="/oem-odm">{t('sub-menu.oem-odm')}</a>
                                    <a className="dropdown-item" href="/facility">{t('sub-menu.facility')}</a>
                                    <a className="dropdown-item" href="/quality-control">{t('sub-menu.quality-control')}</a>
                                    <a className="dropdown-item" href="/patent-certification">{t('sub-menu.patent-certification')}</a>                                                                        
                                    <a className="dropdown-item" href="/r-d-center">{t('sub-menu.rd-center')}</a>
                                </div>                                
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="list-products">{t('menu.products')}</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="#">{t('menu.pr-center')}</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/list-stores">{t('menu.store')}</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="#">{t('menu.customer')}</Link>
                            </li>
                        </ul>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <div className="btn-group">
                            <button type="button" className="btn btn-outline-danger dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {i18n.t('btn-language:key')}&nbsp;&nbsp;
                            </button>
                            <div className="dropdown-menu">
                                <button className="dropdown-item" onClick={() => changeLanguage('en')}>English</button>                                                                
                                <button className="dropdown-item"  onClick={() => changeLanguage('vn')}>Vietnamese</button>                                
                                <Link className="dropdown-item" to="http://ddkorea.co.kr" target="_blank">Korean</Link>
                            </div>
                        </div>                        
                    </div>
                </nav>
            </header>
        );
    }
}

export default withRouter(withNamespaces('translation')(Navigation));