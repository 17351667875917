import React, { Component } from 'react';
import i18n from "i18next";
import { withNamespaces  } from 'react-i18next';

class Footer extends Component {
    componentWillMount() {
        i18n.addResourceBundle('vn', 'content-footer', {
            key: '| Trụ sở / Nhà máy | 586, Đường Nambuk, Mukbuk-myeon, Geumsan-gun, Chungnam<span class="break">&nbsp;</span>· TEL : 041-753-8803 · FAX : 041-753-9914<br />| Văn phòng Seoul | 471, Dongdaemun-gu, Seoul<span class="break">&nbsp;</span>· TEL : 02-964-8808 · FAX : 02-964-8803<br />Bản quyền © 2018 DAEDONG KOREA GINSENG CO., LTD. TẤT CẢ QUYỀN ĐƯỢC ĐẢM BẢO.',
        });
        i18n.addResourceBundle('en', 'content-footer', {
            key: '| Head Office/Factory | 586, Gunbuk-ro, Gunbuk-myeon, Geumsan-gun, Chungcheongnam-do<span class="break">&nbsp;</span>· TEL : 041-753-8803 · FAX : 041-753-9914<br />| Seoul Office | 471, Gosanja-ro, Dongdaemun-gu, Seoul<span class="break">&nbsp;</span>· TEL : 02-964-8808 · FAX : 02-964-8803<br />Copyright © 2018 DAEDONG KOREA GINSENG CO.,LTD. ALL RIGHT RESERVED.',
        });
    }
    render() {
        return (
            <div id="section-9" className="section fp-auto-height" style={{ background: "#888" }}>
                <footer className="footer container">
                    <div className="row">
                        <p className="text-muted col-md-12"><a href="#">Sitemap</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href="#">Location</a></p>
                        <div className="col-md-6">
                            <p id="copyright">
                                <strong>Daedong Korea Ginseng Co., Ltd.</strong><br />
                                <span dangerouslySetInnerHTML={{__html: i18n.t('content-footer:key')}}/>
                            </p>
                        </div>
                        <div className="col-md-6 text-right social">
                            <a href="#"><img className="icon-footer" src={require('../../lib/img/sns-blog.png')} alt="Icon Blog" /></a>
                            <a href="#"><img className="icon-footer" src={require('../../lib/img/sns-facebook.png')} alt="Icon Facebook" /></a>
                            <a href="#"><img className="icon-footer" src={require('../../lib/img/sns-youtube.png')} alt="Icon Youtube" /></a>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default withNamespaces()(Footer);