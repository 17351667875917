import React, { Component } from 'react';
import Navigation from '../header/navigation';
import Contact from '../home-page/Contact';
import Footer from '../footer/Footer';

class PatentCertification extends Component {
    componentWillMount(){
        document.body.classList.remove("isHomePage");       
    }
    render() {
        return (
            <div className="full-screen-scroller">
                <Navigation/>
                <div id="breadcrumbs" style={{backgroundImage: "url(" + require('../../lib/img/bgtop-business-2.jpg') + ")"}}></div>
                <div id="breadcrumbs_box">
                    <div className="container clearfix text-right">
                        <i className="fas fa-home"></i> <a href="/">Trang chủ</a> <i className="fas fa-angle-double-right" style={{fontSize:"10px"}}></i> Bằng sáng chế & Chứng nhận
                    </div>
                </div>
                <div className="container" style={{paddingTop: "40px"}}>
                    <h1 className="title-page">Bằng sáng chế & Chứng nhận</h1>                    
                    <h5 className="sub-title-page text-justify"><i className="fas fa-quote-left"></i>Nhờ vào sức mạnh công nghệ và những nỗ lực không mệt mỏi của chuyên viên nghiên cứu, Daedong Korea Ginseng đang cố gắng sản xuất những sản phẩm có chất lượng tốt nhất.</h5>
                    <div className="row">
                        <h2 className="h2-title-page">| BẰNG SÁNG CHẾ</h2>
                        <div className="col-12 text-des-odm des-qulity-control row" style={{marginTop:"0"}}>
                            <ul className="col-12">                                    
                                <li>No. 10-1120996: Hỗn hợp thành phần chứa ginsenoside Rg3 Rg2 giúp tăng cường khả năng vận động và phục hồi cơ thể</li>
                                <hr className="divider"/>
                                <li>No. 10-1106487: Phương pháp thúc đẩy ginsenoside Rg3 Rh2 trong chiết xuất nhân sâm cô đặc</li>
                                <hr className="divider"/>
                                <li>No. 10-1070770: Phương pháp giảm hàm lượng benzopyrene trong chế biến nhân sâm</li>
                                <hr className="divider"/>
                                <li>No. 10-1160819: Tăng cường hàm lượng ganglioside và sialic acid trong nhung hươu </li>                                
                            </ul>                            
                        </div>
                        <img src={require('../../lib/img/patent-01.jpg')} className="img-fluid mx-auto d-block" alt="Patent Certification"/>
                        <div className="col-12 text-des-odm des-qulity-control row" style={{marginTop:"0"}}>
                            <ul className="col-12">                                    
                                <li>No. 10-1234605: Phương pháp sản xuất và sản xuất Hắc sâm </li>
                                <hr className="divider"/>
                                <li>No. 10-1194731: Phương pháp chế biến hồng sâm lên men dựa theo công thức lên men rượu vang</li>
                                <hr className="divider"/>
                                <li>No. 10-1160819: Phương pháp tăng hàm lượng ginsenoside Rg3 và loại bỏ hàm lượng benzopyrene trong chiết xuất bột hồng sâm</li>
                                <hr className="divider"/>
                                <li>No. 10-1342570: Thực phẩm chức năng kết hợp hồng sâm và hạt óc chó</li>
                                <hr className="divider"/>
                                <li>No. 10-1262764: Phát minh mới và hiệu quả trong chiết xuất hồng sâm cô đặc từ hồng sâm</li>
                                <hr className="divider"/>
                                <li>No. 10-130888: Phương pháp lên men hồng sâm cô đặc dựa vào quá trình lên men vi sinh vật tổng hợp</li>
                                <hr className="divider"/>
                                <li>No. 10-1343820: Phương pháp tách và tinh chế hồng sâm cô đặc để định lượng hàm lượng ginsenoside có độ tinh khiết cao trong sản xuất cao hồng sâm</li>
                                <hr className="divider"/>
                                <li>No. 10-1343819: Phương pháp chế biến bột hồng sâm cô đặc gồm 11 loại ginsenoside quan trọng giúp giảm stress kết hợp các vitamin, khoáng chất, các thành phần ổn định huyết áp, chống dị ứng. Phương pháp này cũng được áp dụng trong chế biến và sản xuất hắc sâm</li>
                                <hr className="divider"/>
                                <li>No. 10-1439102: Phát minh mới trong chiết xuất quả nhân sâm cô đặc và nước ép từ hạt nhân sâm</li>
                                <hr className="divider"/>
                                <li>No. 10-1603171: Phương pháp tăng cường hàm lượng polysacarit và ginsenoside trong chiết xuất nhân sâm</li>
                                <hr className="divider"/>
                                <li>No. 10-1603169: Phương pháp tăng cường ginsenoside trong chiết xuất quả nhân sâm </li>
                                <hr className="divider"/>
                                <li>No. 10-1607452: Phương pháp tách dễ dàng và giảm thiểu hàm lượng benzopyrene trong chế biến hắc sâm</li>
                                <hr className="divider"/>
                                <li>No. 10-1679388: Phương pháp bổ sung hàm lượng ganglioside và axit sialic trong sản xuất nhung hươu và nhung hươu lên men. Các sản phẩm tốt cho sức khỏe khớp & sụn đều được áp dụng phương pháp này. </li>
                                <hr className="divider"/>
                                <li>No. 10-1738664: Phương pháp sản xuất món ăn nhẹ làm từ hồng sâm kết hợp với mật ong có chứa một lượng lớn isoflavone bằng cách phủ lớp tinh bột Pueraria Radix</li>
                                <hr className="divider"/>
                                <li>No. 10-2016-0155048: Phương pháp sản xuất và sản xuất nước giải khát hồng sâm không có kết tủa</li>                                
                            </ul>                            
                        </div>
                        <h2 className="h2-title-page">| Chứng nhận</h2>
                        <h5 className="col-12 sub-title-page text-justify" style={{marginBottom:'0px'}}><i className="fas fa-quote-left"></i>Tập đoàn Daedong Korea Ginseng định hướng “Chất lượng cao quyết định sự hài lòng của khách hàng” bằng các chính sách an toàn thực phẩm và nỗ lực quản lý an toàn thực phẩm.</h5>
                        <h5 className="col-12 sub-title-page text-justify">Công ty Daedong Korea Ginseng đã được cấp chứng nhận GAP (Thực hành nông nghiệp tốt) về quản lý vệ sinh an toàn nguồn nhân sâm. Chúng tôi đã được Bộ Việc làm và Lao động Hàn Quốc công nhận là địa điểm SẠCH để duy trì môi trường sản xuất an toàn. Ngoài ra Daedong Korea Ginseng còn được cấp các chứng chỉ ISO22000:2005, và ứng dụng tiêu chuẩn GMP (Thực hành sản xuất tốt) và HACCP (Phân tích nguy cơ và điểm kiểm tới hạn) trong quản lý an toàn thực phẩm.</h5>
                        <img src={require('../../lib/img/patent-02.jpg')} className="img-fluid mx-auto d-block" alt="Patent Certification"/>
                        <img src={require('../../lib/img/patent-03.jpg')} style={{marginBottom:"30px"}} className="img-fluid mx-auto d-block" alt="Patent Certification"/>
                        <h2 className="h2-title-page">| Giải thưởng</h2>
                        <h5 className="col-12 sub-title-page text-justify"><i className="fas fa-quote-left"></i>Tập đoàn Daedong Korea Ginseng được Chính Phủ Hàn Quốc và Thống đốc tỉnh Chungcheongnam khen thưởng và công nhận là doanh nghiệp tiêu biểu và có đóng góp cho ngành chế biến nông sản Hàn Quốc.</h5>
                        <img src={require('../../lib/img/patent-04.jpg')} className="img-fluid mx-auto d-block" alt="Patent Certification"/>
                    </div>
                </div>
               
                <Contact/>

                <Footer/>
                
            </div>
        );
    }
}

export default PatentCertification;