import React, { Component } from 'react';
import Navigation from '../header/navigation';
import Contact from '../home-page/Contact';
import Footer from '../footer/Footer';
import $ from 'jquery';

class AboutGinssen extends Component {
    componentDidMount(){
        document.body.classList.add("isAboutGinssen");  
    }
    render() {
        $('body').removeAttr( "class" );
        return (
            <div className="full-screen-scroller">
                <Navigation/>
                <div id="breadcrumbs"></div>
                <div id="breadcrumbs_box">
                    <div className="container clearfix text-right">
                        <i className="fas fa-home"></i> <a href="/">Trang chủ</a> <i className="fas fa-angle-double-right" style={{fontSize:"10px"}}></i> GINSSEN 진쎈
                    </div>
                </div>
                <div className="container" style={{paddingTop: "40px"}}>
                    <h1 className="title-page">GINSSEN 진쎈</h1>
                    <h5 className="sub-title-page"><i className="fas fa-quote-left"></i>Thương hiệu hắc sâm cao cấp của Daedong Korea Ginseng Co., Ltd</h5>
                    <img src={require('../../lib/img/about_black_ginssen1.jpg')} className="img-fluid" alt="About Ginssen"/>
                    <div className="row" style={{marginTop:"25px"}}>
                        <h2 className="h2-title-page col-12">| 진쎈(GINSSEN)</h2>
                        <div className="col-12 col-sm-9">
                            <p className="text-justify">
                                Nguồn nguyên liệu tạo nên những sản phẩm hắc sâm thuộcThương hiệu hắc sâm cao cấp của Daedong Korea Ginseng Co., Ltd đều là những của sâm 6 năm tuổi tuyển chọn chất lượng cao. Đây là một thương hiệu nhân sâm cao cấp bậc nhất trên thị trường hiện nay. nhân sâm tuyển chọn được trải qua quá trình hấp sấy liên tục 9 lần liên tục “9 lần hấp 9 lần sấy” (九 蒸 九 射) không chỉ tạo nên màu đen cho nhân sâm mà còn mang đến 1 hương vị và mùi thơm đặc biệt riêng có cho Hắc sâm Hàn quốc.
                            </p>
                        </div>
                        <div className="col-12 col-sm-3">
                            <img src={require('../../lib/img/logo-ginssen.png')} className="img-fluid mx-auto d-block" alt="About Ginssen"/>
                        </div>
                        <div className="row justify-content-end" id="bg-content-black-ginssen">                            
                            <div className="col-12">
                                <h2 style={{marginBottom:'20px'}}>| Hắc sâm (BLACK GINSENG)</h2>
                                <p style={{fontWeight: "bold"}}>Trong những cuốn sách cổ y học của Hàn quốc như  醫學入門 (Giới thiệu thuốc thảo dược) hay本草綱目 (Bản  tóm tắt những dòng thảo dược quý) từng ghi rằng: “Phương pháp 9 lần hấp – 9 lần sấy구증구포 ((九蒸九曝)) được lặp đi lặp lại liên tục là một phương pháp tuyệt vời, giúp tối đa hóa hàm lượng saponin cũng như phát huy các thành phần khác có lợi cho cơ thể người sử dụng”.</p>
                                <p>Chính nhờ 9 lần hấp sấy kết hợp với thảo dược quý hiếm khiến các thành phần saponin có lợi của nhân sâm chuyển từ dạng “sol” (Sợi) thành dạng “gel”(tinh chất), giúp cơ thể dễ hấp thụ và tiêu hóa tốt hơn so với nhân sâm tươi và hồng sâm.</p>
                                <p>Quá trình này còn khiến các thành phần enzym  ngưng hoạt tính, khiến các quá trình biến đổi và chuyển hóa về chất bị ngưng lại, giúp duy trì hàm lượng chất dinh dưỡng ở trạng thái nguyên dạng, kéo dài thời gian sử dụng và bảo quản tốt hơn (được coi là dạng “chất bảo quản” tự nhiên có lợi).</p>
                            </div>
                        </div>
                        <h2 className="h2-title-page">| Đặc trưng của GINSSEN (Hắc sâm)</h2>
                        <div className="col-12 dac-trung">                            
                            <div className="row">
                                <div className="col-sm-3 leaderline">
                                    <img src={require('../../lib/img/about_black_ginssen_icon1.png')} alt="Icon Ginssen1"/>
                                </div>
                                <div className="col-sm-9" style={{paddingLeft: "30px"}}>
                                    <h4 style={{paddingTop:"30px"}}>Hắc sâm nội địa 6 năm tuổi</h4>
                                    <p>Là dòng sản phẩm hắc sâm cao cấp sử dụng 100% Nhân sâm 6 năm tuổi tuyển chọn</p>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-sm-3 leaderline">
                                    <img src={require('../../lib/img/about_black_ginssen_icon2.png')} alt="Icon Ginssen1"/>
                                </div>
                                <div className="col-sm-9" style={{paddingLeft: "30px"}}>
                                    <h4 style={{paddingTop:"30px"}}>Hắc sâm với phương pháp 9 lần hấp – 9 lần sấy구증구포 ((九蒸九曝))</h4>
                                    <p>Là dòng sản phẩm sử dụng phương pháp chế biến 9 lần hấp – 9 lần sấy truyền thống của Đại hàn dân quốc hàng trăm năm nay.</p>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-sm-3 leaderline">
                                    <img src={require('../../lib/img/about_black_ginssen_icon3.png')} alt="Icon Ginssen1"/>
                                </div>
                                <div className="col-sm-9" style={{paddingLeft: "30px"}}>
                                    <h4 style={{paddingTop:"30px"}}>Hệ thống sấy tự nhiên bằng đá Elvan</h4>
                                    <p>Là phương pháp duy trì các thành phần và lưu giữ “các hợp chất dinh dưỡng” vốn có trong hồng sâm và đem lại hiệu quả tốt hơn trong quá trình sản xuất các dòng sản phẩm chiết xuất.</p>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-sm-3 leaderline">
                                    <img src={require('../../lib/img/about_black_ginssen_icon4.png')} alt="Icon Ginssen1"/>
                                </div>
                                <div className="col-sm-9" style={{paddingLeft: "30px"}}>
                                    <h4 style={{paddingTop:"30px"}}>Công nghệ sản xuất tập trung Fusion mới</h4>
                                    <p>Hệ thống tập trung (Falling Film) giúp lưu giữ, duy trì được hương vị ban đầu và mùi thơm vốn có của hắc sâm.</p>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-sm-3 leaderline">
                                    <img src={require('../../lib/img/about_black_ginssen_icon5.png')} alt="Icon Ginssen1"/>
                                </div>
                                <div className="col-sm-9" style={{paddingLeft: "30px"}}>
                                    <h4 style={{paddingTop:"30px"}}>Tiểu chuẩn hóa thành phần Rg3</h4>
                                    <p>Trong số các ginsenosides saponin thì thành phần Rg3 trong các Hắc sâm đã được tiêu chuẩn hóa, là 1 bước nâng cấp hơn so với các dòng Nhân sâm và Hồng sâm (chưa được tiểu chuẩn hóa thành phần Rg3)</p>
                                </div>
                            </div>
                            
                        </div>
                        <div className="col-12 text-center" style={{marginTop:"20px"}}>
                            <img src={require('../../lib/img/about_black_ginssen_icon6.png')} className="img-fluid" alt="Sản phẩm từ hắc sâm"/>
                        </div>
                    </div>
                </div>
                <Contact/>

                <Footer/>   
            </div>
        );
    }
}

export default AboutGinssen;