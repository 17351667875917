import React, { Component } from 'react';
import Navigation from '../header/navigation';
import Contact from '../home-page/Contact';
import Footer from '../footer/Footer';

class OEM_ODM extends Component {
    componentWillMount(){
        document.body.classList.remove("isHomePage");       
    }
    render() {
        return (
            <div className="full-screen-scroller">
                <Navigation/>
                <div id="breadcrumbs" style={{backgroundImage: "url(" + require('../../lib/img/bgtop-business-2.jpg') + ")"}}></div>
                <div id="breadcrumbs_box">
                    <div className="container clearfix text-right">
                        <i className="fas fa-home"></i> <a href="/">Trang chủ</a> <i className="fas fa-angle-double-right" style={{fontSize:"10px"}}></i> OEM/ODM
                    </div>
                </div>
                <div className="container" style={{paddingTop: "40px"}}>
                    <h1 className="title-page">Sản xuất OEM & ODM</h1>                    
                    <h5 className="sub-title-page"><i className="fas fa-quote-left"></i>Daedong Korea Ginseng Co., Ltd cung cấp các sản phẩm chất lượng cao và an toàn cho khách hàng của chúng tôi theo phương thức sản xuất ODM và OEM.</h5>
                    <div className="row" style={{paddingTop: "40px"}}>
                        <div className="col-12 col-sm-3">
                            <h2 className="h2-title-page">ODM - Original Development Manufacturing </h2>
                            <p className="text-justify">
                                <em>(Sản xuất phát triển sản phẩm gốc)</em><br/>
                                Kế hoạch sản phẩm, Phát triển, Sản xuất,
                                Đây là một phương thức Sản xuất phát triển sản phẩm gốc, tự chịu trách nhiệm kiểm soát chất lượng và giao hàng.
                            </p>
                        </div>
                        <div className="col-12 col-sm-9" >
                            <h5 className="col-12">Quy trình ODM</h5>
                            <div className="row">
                                <div className="col-md-3 icon-oem-odm">
                                    <div className="content-icon-oem d-flex align-content-center flex-wrap">
                                        <img src={require('../../lib/img/OEMODM-icon-02.png')} className="img-fluid mx-auto d-block" alt="Icon ODM/ODM"/>
                                        <p>Nghiên cứu thị trường và xem xét nhu cầu</p>
                                    </div>                                    
                                </div>
                                <div className="col-md-3 icon-oem-odm">
                                    <div className="content-icon-oem d-flex align-content-center flex-wrap">
                                        <img src={require('../../lib/img/OEMODM-icon-03.png')} className="img-fluid mx-auto d-block" alt="Icon ODM/ODM"/>
                                        <p>Kế hoạch sản phẩm</p>
                                    </div>                                    
                                </div>
                                <div className="col-md-3 icon-oem-odm">
                                    <div className="content-icon-oem d-flex align-content-center flex-wrap">
                                        <img src={require('../../lib/img/OEMODM-icon-04.png')} className="img-fluid mx-auto d-block" alt="Icon ODM/ODM"/>
                                        <p>Đánh giá ban đầu</p>
                                    </div>                                    
                                </div>
                                <div className="col-md-3 icon-oem-odm">
                                    <div className="content-icon-oem d-flex align-content-center flex-wrap">
                                        <img src={require('../../lib/img/OEMODM-icon-05.png')} className="img-fluid mx-auto d-block" alt="Icon ODM/ODM"/>
                                        <p>Báo cáo</p>
                                    </div>                                    
                                </div>
                                <div className="col-md-3 icon-oem-odm">
                                    <div className="content-icon-oem d-flex align-content-center flex-wrap">
                                        <img src={require('../../lib/img/OEMODM-icon-06.png')} className="img-fluid mx-auto d-block" alt="Icon ODM/ODM"/>
                                        <p>Đánh giá quảng cáo</p>
                                    </div>                                    
                                </div> 
                                <div className="col-md-3 icon-oem-odm">
                                    <div className="content-icon-oem d-flex align-content-center flex-wrap">
                                        <img src={require('../../lib/img/OEMODM-icon-07.png')} className="img-fluid mx-auto d-block" alt="Icon ODM/ODM"/>
                                        <p>Đặt hàng & nhận nguyên liệu phụ</p>
                                    </div>                                    
                                </div>     
                                <div className="col-md-3 icon-oem-odm">
                                    <div className="content-icon-oem d-flex align-content-center flex-wrap">
                                        <img src={require('../../lib/img/OEMODM-icon-08.png')} className="img-fluid mx-auto d-block" alt="Icon ODM/ODM"/>
                                        <p>Sản xuất & kiểm tra sản phẩm</p>
                                    </div>                                    
                                </div>
                                <div className="col-md-3 icon-oem-odm">
                                    <div className="content-icon-oem d-flex align-content-center flex-wrap">
                                        <img src={require('../../lib/img/OEMODM-icon-09.png')} className="img-fluid mx-auto d-block" alt="Icon ODM/ODM"/>
                                        <p>Giao hàng tận nơi</p>
                                    </div>                                    
                                </div>                           
                            </div>
                        </div>
                    </div>
                     {/* ----ODM---  */}
                    <div className="row" style={{paddingTop: "40px"}}>
                        <div className="col-12 col-sm-3">
                            <h2 className="h2-title-page">OEM - Original Equipment Manufacturing </h2>
                            <p className="text-justify">
                                <em>(Sản xuất thiết bị gốc)</em><br/>
                                Đây là một hệ thống sản xuất được ủy quyền để sản xuất sản phẩm và cung cấp các nhãn hiệu sản phẩm theo nhu cầu của khách hàng, người đặt hàng.
                            </p>
                        </div>
                        <div className="col-12 col-sm-9" >
                            <h5 className="col-12">Quy trình OEM</h5>
                            <div className="row">
                                <div className="col-md-3 icon-oem-odm">
                                    <div className="content-icon-oem d-flex align-content-center flex-wrap">
                                        <img src={require('../../lib/img/OEMODM-icon-01.png')} className="img-fluid mx-auto d-block" alt="Icon ODM/ODM"/>
                                        <p>Tiếp nhận yêu cầu</p>
                                    </div>                                    
                                </div>                                
                                <div className="col-md-3 icon-oem-odm">
                                    <div className="content-icon-oem d-flex align-content-center flex-wrap">
                                        <img src={require('../../lib/img/OEMODM-icon-04.png')} className="img-fluid mx-auto d-block" alt="Icon ODM/ODM"/>
                                        <p>Đánh giá ban đầu</p>
                                    </div>                                    
                                </div>
                                <div className="col-md-3 icon-oem-odm">
                                    <div className="content-icon-oem d-flex align-content-center flex-wrap">
                                        <img src={require('../../lib/img/OEMODM-icon-05.png')} className="img-fluid mx-auto d-block" alt="Icon ODM/ODM"/>
                                        <p>Báo cáo</p>
                                    </div>                                    
                                </div>
                                <div className="col-md-3 icon-oem-odm">
                                    <div className="content-icon-oem d-flex align-content-center flex-wrap">
                                        <img src={require('../../lib/img/OEMODM-icon-06.png')} className="img-fluid mx-auto d-block" alt="Icon ODM/ODM"/>
                                        <p>Đánh giá quảng cáo</p>
                                    </div>                                    
                                </div> 
                                <div className="col-md-3 icon-oem-odm">
                                    <div className="content-icon-oem d-flex align-content-center flex-wrap">
                                        <img src={require('../../lib/img/OEMODM-icon-07.png')} className="img-fluid mx-auto d-block" alt="Icon ODM/ODM"/>
                                        <p>Đặt hàng & nhận nguyên liệu phụ</p>
                                    </div>                                    
                                </div>     
                                <div className="col-md-3 icon-oem-odm">
                                    <div className="content-icon-oem d-flex align-content-center flex-wrap">
                                        <img src={require('../../lib/img/OEMODM-icon-08.png')} className="img-fluid mx-auto d-block" alt="Icon ODM/ODM"/>
                                        <p>Sản xuất & kiểm tra sản phẩm</p>
                                    </div>                                    
                                </div>
                                <div className="col-md-3 icon-oem-odm">
                                    <div className="content-icon-oem d-flex align-content-center flex-wrap">
                                        <img src={require('../../lib/img/OEMODM-icon-09.png')} className="img-fluid mx-auto d-block" alt="Icon ODM/ODM"/>
                                        <p>Giao hàng tận nơi</p>
                                    </div>                                    
                                </div>                           
                            </div>
                        </div>
                    </div>

                    <h5 className="sub-title-page" style={{marginTop:"80px"}}><i className="fas fa-quote-left"></i>Daedong Korea Ginseng Co., Ltd sản xuất các sản phẩm sâm nguyên củ, chiết xuất nhân sâm cô đặc, các sản phẩm dạng bột, hạt, viên nén, viên nang. Chúng tôi có nhiều dòng sản phẩm theo từng loại, công dụng và đặc điểm của sản phẩm.</h5>
                    <div className="d-flex align-content-center flex-wrap text-oem-odm-bg">
                        <p>“Các sản phẩm sản xuất  OEM /ODM và các loại sản phẩm<br/>đáp ứng theo nhu cầu của khách hàng (doanh nghiệp/ công ty đặt hàng)”</p>
                    </div>
                    <div className="col-12 text-des-odm">
                        <h2 className="h2-title-page">Thực phẩm chức năng & thực phẩm theo định hướng sức khỏe</h2>
                        <p className="text-justify">
                            <strong>Điểm mạnh:</strong> Công ty sản xuất và chế biến sản phẩm hồng sâm được chứng nhận GMP, HACCP
                            <br/><strong>Năng lực:</strong> Thay đổi nhận thức về chức năng của thực phẩm bảo vệ sức khỏe đói với kinh tế, xã hội và mô hình tiêu dùng
                            <br/><strong>Định hướng:</strong> Sản xuất và cung cấp thực phẩm sức khỏe chất lượng cao hướng tới hạnh phúc bền vững cho cộng đồng
                        </p>
                    </div>
                    <div className="col-12 text-des-odm">
                        <h2 className="h2-title-page">Sản phẩm từ Nhân sâm</h2>
                        <p className="text-justify">
                            <strong>Điểm mạnh:</strong> Đạt chứng nhận GAP, nguồn nguyên liệu thô ổn định (hồng sâm, bạch sâm, thái cực sâm, hắc sâm)
                            <br/><strong>Năng lực:</strong> Các thành phần nguyên liệu hoàn toàn không phụ gia và loại bỏ các thành phần hóa học không tốt cho cơ thể từ sản nguyên liệu thô.
                            <br/><strong>Định hướng:</strong> Xây dựng và phát triển thương hiệu vươn xa toàn cầu.
                        </p>
                    </div>
                    <div className="row" style={{padding:"0 15px"}}>
                        <h2 className="h2-title-page">| SẢN XUẤT ĐA DẠNG CÁC DÒNG SẢN PHẨM</h2>
                        <p className="text-justify">
                            Daedong Korea Ginseng Co., Ltd với lịch sử sản xuất và Kinh doanh nhân sâm và gia công hồng sâm trong một thời gian dài cùng với bí quyết cùng những công trình nghiên cứu không ngừng, chúng tôi tin mình có khả năng “biết và làm như thế nào” (know-how), để phát triển dòng sản phẩm làm hài lòng mọi khách hàng theo phương thức sản xuất OEM, cũng như khái niệm mới khác biệt của ODM.
                        </p>
                    </div>
                    <div className="col-12 text-des-odm">
                        <h2 className="h2-title-page">Phạm vi sản xuất</h2>
                        <p className="text-justify">
                            Nhân sâm nguyên bản (rễ sâm), thực phẩm chức năng bảo vệ sức khỏe, Gia công sản phẩm Hồng sâm, thực phẩm chế biến nói chung, thực phẩm khác, Sản phẩm nguyên liệu thảo dược Đông y, Gia công nguyên liệu chế biến.
                        </p>
                    </div>
                    <div className="col-12 text-des-odm">
                        <h2 className="h2-title-page">Các dòng sản phẩm sản xuất</h2>
                        <p className="text-justify">
                            Viên nang, viên nén - tablet, bột, dạng hạt, hạt dạng nhỏ, chất lỏng (túi), cô đặc, thuốc viên, trà, bột SD, chất lỏng có độ sánh cao, lên men, và các sản phẩm khác.
                        </p>
                    </div>
                    <div className="col-12 text-des-odm">
                        <h2 className="h2-title-page">Bao bì sản xuất</h2>
                        <p className="text-justify">
                            PTP (mềm / cứng / tinh chế), bao bì chai (PE / thủy tinh), bao bì dính, bao bì ba mặt, bao bì bốn mặt, bao bì hình ảnh, bao bì đứng, bao bì hộp sắt, và các dạng đóng gói khác.
                        </p>
                    </div>
                </div>                                                
                               
                <Contact/>

                <Footer/>
            </div>
        );
    }
}

export default OEM_ODM;