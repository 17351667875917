import React, { Component } from 'react';
import Navigation from '../header/navigation';
import Contact from '../home-page/Contact';
import Footer from '../footer/Footer';
import $ from 'jquery';

class LocationMap extends Component {
    componentDidMount(){
        document.body.classList.add("isLocationMap");  
    }
    render() {
        $('body').removeAttr( "class" );
        return (
            <div className="full-screen-scroller">
                <Navigation/>
                <div id="breadcrumbs" style={{backgroundImage: "url(" + require('../../lib/img/bgtop-company-5.jpg') + ")"}}></div>
                <div id="breadcrumbs_box">
                    <div className="container clearfix text-right">
                        <i className="fas fa-home"></i> <a href="/">Trang chủ</a> <i className="fas fa-angle-double-right" style={{fontSize:"10px"}}></i> Thông tin vị trí
                    </div>
                </div>
                <div className="container" style={{paddingTop: "40px"}}>
                    <h1 className="title-page">Thông  tin vị trí</h1>                    
                    <h5 className="sub-title-page"><i className="fas fa-quote-left"></i>Chúng tôi hoan nghênh mọi chuyến tham quan của quý khách hàng  đến với Daedong</h5>
                    <img src={require('../../lib/img/location.jpg')} className="img-fluid mx-auto d-block" alt="Location"/>
                    <div className="col-12 des-qulity-control" style={{margin:"40px 0"}}>
                        <h2 className="h2-title-page">| DAEDONG KOREA GINSENG Co., Ltd.</h2>
                        <ul style={{paddingLeft:"0"}}>
                            <li>Trụ sở chính / Nhà máy</li>
                            <li>Địa chỉ: 586 Nambuk Road, Mukbuk-myeon, Geumsan-gun, Chungnam</li>
                            <li>Điện thoại: 041-753-8804</li>
                            <li>Fax: 041-753-9914</li>
                        </ul>
                    </div>
                    <div className="col-12">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6442.84854366403!2d127.53003!3d36.156229!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35655bbd2565e6b1%3A0x1d4c325ce3eedf33!2s586+Gunbuk-ro%2C+Gunbuk-myeon%2C+Geumsan%2C+Chungcheongnam-do!5e0!3m2!1svi!2skr!4v1560506102409!5m2!1svi!2skr" width="100%" height="450" frameborder="0" style={{border:0}} allowfullscreen></iframe>
                    </div>
                    <div className="col-12 des-qulity-control" style={{margin:"40px 0"}}>
                        <h2 className="h2-title-page">| Văn phòng Seoul Daedong HÀN QUỐC.</h2>
                        <ul style={{paddingLeft:"0"}}>
                            <li>Trụ sở chính / Nhà máy</li>
                            <li>Địa chỉ: 471, Jegi-dong, Dongdaemun-gu, Seoul</li>
                            <li>Điện thoại: 02-964-8808</li>
                            <li>Fax: 02-964-8804</li>
                        </ul>
                    </div>
                    <div className="col-12">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6323.739980790586!2d127.038255!3d37.581679!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357cbca7d961ed69%3A0x66e8e5e2fdb2c041!2s930-7+Jegi-dong%2C+Dongdaemun-gu%2C+Seoul!5e0!3m2!1svi!2skr!4v1560506046131!5m2!1svi!2skr" width="100%" height="450" frameborder="0" style={{border:0}} allowfullscreen></iframe>
                    </div>
                    <div className="col-12 des-qulity-control" style={{margin:"40px 0"}}>
                        <h2 className="h2-title-page">| Đại diện tại Việt Nam.</h2>
                        <ul style={{paddingLeft:"0"}}>
                            <li>VHP Ginseng</li>
                            <li>Địa chỉ: 27D Lý Thái Tổ, Hoàn Kiếm, Hà Nội</li>
                            <li>Điện thoại: 0243 373 8899 - 0985 118 368</li>                            
                        </ul>
                    </div>
                    <div className="col-12">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.068424625747!2d105.85303531492956!3d21.02994799311361!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135abea811a73c9%3A0x513cd582e3278ff2!2zMjdkIFBo4buRIEzDvSBUaMOhaSBU4buVLCBMw70gVGjDoWkgVOG7lSwgSG_DoG4gS2nhur9tLCBIw6AgTuG7mWkgMTAwMDAwLCBWaeG7h3QgTmFt!5e0!3m2!1svi!2skr!4v1560506741679!5m2!1svi!2skr" width="100%" height="450" frameborder="0" style={{border:0}} allowfullscreen></iframe>
                    </div>
                </div>
                

                <Contact/>

                <Footer/>
                
            </div>
        );
    }
}

export default LocationMap;