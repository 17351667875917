import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import $ from 'jquery';

class NotFound extends Component {
    componentDidMount(){
        document.body.classList.add("isNotFound");
    }
    render() {
        $('body').removeAttr( "class" );
        return (
            <div className="card bg-danger">
                <div className="card-body text-center">
                    <p className="card-text">Đường dẫn sai! Quay lại >>> <Link to="/">Trang chủ</Link></p>
                </div>
            </div> 
        );
    }
}

export default withRouter(NotFound);