import React, { Component } from 'react';
import Navigation from '../header/navigation';
import Contact from '../home-page/Contact';
import Footer from '../footer/Footer';
import $ from 'jquery';

class RD_Center extends Component {
    componentDidMount(){
        document.body.classList.add("isRdCenter");
    }
    render() {
        $('body').removeAttr( "class" );
        return (
            <div className="full-screen-scroller">
                <Navigation/>
                <div id="breadcrumbs" style={{backgroundImage: "url(" + require('../../lib/img/bgtop-business-2.jpg') + ")"}}></div>
                <div id="breadcrumbs_box">
                    <div className="container clearfix text-right">
                        <i className="fas fa-home"></i> <a href="/">Trang chủ</a> <i className="fas fa-angle-double-right" style={{fontSize:"10px"}}></i> Trung tâm nghiên cứu R&D
                    </div>
                </div>
                <div className="container" style={{paddingTop: "40px"}}>
                    <h1 className="title-page">Trung tâm nghiên cứu R&D</h1>                    
                    <h5 className="sub-title-page text-justify"><i className="fas fa-quote-left"></i>Tại đây, các nhà nghiên cứu hàng đầu có thẩm quyền liên tục nghiên cứu và phát triển dựa trên các cơ sở nghiên cứu với thiết bị thí nghiệm hiện đại,  các công nghệ được cấp bằng sáng chế khác nhau.
                        <br/>Ngoài ra Daedong Korea Ginseng là một trong những doanh nghiệp hàng đầu về ngành nhân sâm học tại Hàn Quốc và ngành y dược của Đông Nam Á, trực thuộc Trung tâm Nghiên cứu Công nghiệp Sâm Ginseng Đại học Chung-Ang và Hiệp hội Dược phẩm Hàn Quốc.
                    </h5>
                    <img src={require('../../lib/img/rd-center-01.png')} className="img-fluid mx-auto d-block" alt="R&D Center"/>
                    <h2 className="h2-title-page" style={{marginTop: "40px"}}>| Công nghệ đang phát triển tại Trung tâm nghiên cứu R&D:</h2>
                    <div className="col-12 des-qulity-control row">
                        <ul className="col-12">                                    
                            <li>Công nghệ Thixotropy (증삼기술): Tăng hiệu suất của quy trình sản xuất, công nghệ xử lý với áp suất cao</li>
                            <li>Công nghệ chiết và tập trung (추출농축기술): Tăng hiệu quả của quá trình, công nghệ không phá hủy hàm lượng dưỡng chất vốn có trong sản phẩm</li>
                            <li>Công nghệ lên men (발효기술): Tăng hiệu suất xử lý, vi sinh vật mới</li>
                            <li>Công nghệ chuẩn hóa định lượng (정량표준화기술): Định lượng ginsenosides</li>
                            <li>Công nghệ chuyển đổi/tách, công nghệ tinh chế (변환기술/분리정제기술): ginsenoside độ tinh khiết cao, phát triển axit polysaccharide</li>
                            <li>Công nghệ phát triển các thành phần thảo dược tự nhiên (천연한방소재 개발): Phát triển những thành phần tự nhiên có sẵn trong thảo dược</li>  
                        </ul>                            
                    </div>
                    <img src={require('../../lib/img/rd-center-02.png')} className="img-fluid mx-auto d-block" alt="R&D Center"/>
                    <h2 className="h2-title-page" style={{marginTop: "40px"}}>| Hướng nghiên cứu và phát triển của Daedong trong tương lai:</h2>
                    <h5 className="sub-title-page text-justify"><i className="fas fa-quote-left"></i>Daedong Korea Ginseng luôn nỗ lực liên tục, phấn đấu cho những nghiên cứu nhằm đưa ra những dòng sản phẩm tốt nhất, chính xác nhất, hướng tới một xã hội mà mỗi cá nhân đều duy trì được cuộc sống khỏe mạnh, và hạnh phúc.</h5>
                    <div className="row" style={{paddingTop: "40px"}}>
                        <div className="col-12 col-sm-4 des-rd-center">
                            <ul>
                                <li style={{listStyle: 'none', marginBottom: "10px",marginLeft: "-20px"}}><img src={require('../../lib/img/rd-center-icon5.png')} className="img-fluid" alt="R&D Center"/></li>
                                <li>Công nghệ lên men cô đặc</li>
                                <li>Công nghệ tẩy tách</li>
                                <li>Công nghệ chuyển đổi cấu trúc</li>
                                <li>Tiêu chuẩn định lượng</li>
                            </ul>                        
                        </div>
                        <div className="col-12 col-sm-8" >                            
                            <div className="row">
                                <div className="col-md-6 icon-oem-odm">
                                    <div className="content-icon-oem d-flex align-content-center flex-wrap" style={{padding:"80px 10px", margin:'0 0 30px 0'}}>
                                        <img src={require('../../lib/img/rd-center-icon-01.png')} className="img-fluid mx-auto d-block" alt="Icon R&D Center"/>
                                        <p>Nguyên liệu cho dược phẩm</p>
                                    </div>
                                </div>
                                <div className="col-md-6 icon-oem-odm">
                                    <div className="content-icon-oem d-flex align-content-center flex-wrap" style={{padding:"80px 10px", margin:'0 0 30px 0'}}>
                                        <img src={require('../../lib/img/rd-center-icon-02.png')} className="img-fluid mx-auto d-block" alt="Icon R&D Center"/>
                                        <p>Thực phẩm chức năng sức khoẻ</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 icon-oem-odm">
                                    <div className="content-icon-oem d-flex align-content-center flex-wrap" style={{padding:"80px 10px"}}>
                                        <img src={require('../../lib/img/rd-center-icon-03.png')} className="img-fluid mx-auto d-block" alt="Icon R&D Center"/>
                                        <p>Thành phầm mỹ phẩm ăn toàn</p>
                                    </div>
                                </div>
                                <div className="col-md-6 icon-oem-odm">
                                    <div className="content-icon-oem d-flex align-content-center flex-wrap" style={{padding:"80px 10px"}}>
                                        <img src={require('../../lib/img/rd-center-icon-04.png')} className="img-fluid mx-auto d-block" alt="Icon R&D Center"/>
                                        <p>Thực phẩm thiên nhiên hiệu quả cao</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Contact/>

                <Footer/>
            </div>
        );
    }
}

export default RD_Center;