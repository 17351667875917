import React, { Component } from 'react';
import Navigation from '../header/navigation';
import Contact from '../home-page/Contact';
import Footer from '../footer/Footer';
import $ from 'jquery';

class CleanSystemGinssen extends Component {
    componentDidMount(){
        document.body.classList.add("isCleanSystemGinssen");  
    }
    render() {
        $('body').removeAttr( "class" );
        return (
            <div className="full-screen-scroller">
                <Navigation/>
                <div id="breadcrumbs" style={{backgroundImage: "url(" + require('../../lib/img/bgtop-system-03-1.jpg') + ")"}}></div>
                <div id="breadcrumbs_box">
                    <div className="container clearfix text-right">
                        <i className="fas fa-home"></i> <a href="/">Trang chủ</a> <i className="fas fa-angle-double-right" style={{fontSize:"10px"}}></i> Hệ thống làm sạch cao cấp
                    </div>
                </div>
                <div className="container" style={{paddingTop: "40px"}}>
                    <h1 className="title-page">Hệ thống làm sạch, vệ sinh cao cấp</h1>
                    <h5 className="sub-title-page"><i className="fas fa-quote-left"></i>Daedong Korea Ginseng Co.,Ltd lựa chọn nguyên liêu chất lượng cao và sản xuất với hệ thống sản xuất tiên tiến và hiện đại nhất</h5>
                    <img src={require('../../lib/img/clean-ginssen01.jpg')} className="img-fluid mx-auto d-block" alt="Clean System Ginssen"/>                    
                    <div className="row" style={{marginTop:"25px"}}>
                        <h2 className="h2-title-page col-12 text-justify">| Hệ thống xử lý và làm sạch hiện đại tại Nhà máy Daedong Korea Ginseng</h2>
                        <p className="text-justify col-12">
                            Chúng tôi chỉ sản xuất và cung cấp các sản phẩm sạch và an toàn tới người tiêu dùng với nguyên liệu chất lượng cao nhất và hệ thống sản xuất tiên tiến của Daedong Korea Ginseng Co.,
                        </p>
                        <div className="col-12 col-sm-4 text-center">
                            <img src={require('../../lib/img/clean-ginssen-icon-01.png')} className="img-fluid mx-auto d-block" alt="Fusion Ginssen"/>
                            <h5>| Tập trung làm giảm nồng độ |</h5>
                        </div>
                        <div className="col-12 col-sm-4 text-center">
                            <img src={require('../../lib/img/clean-ginssen-icon-02.png')} className="img-fluid mx-auto d-block" alt="Fusion Ginssen"/>
                            <h5>| Giữ nguyên các hợp chất dinh dưỡng vốn có |</h5>
                        </div>
                        <div className="col-12 col-sm-4 text-center">
                            <img src={require('../../lib/img/clean-ginssen-icon-03.png')} className="img-fluid mx-auto d-block" alt="Fusion Ginssen"/>
                            <h5>| Tuần hoàn tự nhiên các hợp chất |</h5>
                        </div>
                        <p className="col-12 text-center">
                        <i className="fas fa-sun"></i> Tiêu chuẩn HACCP: HACCP là viết tắt của cụm từ Hazard Analysis and Critical Control Point System, và có nghĩa là “hệ thống phân tích mối nguy và kiểm soát điểm tới hạn”, hay hệ thống phân tích, xác định và tổ chức kiểm soát các mối nguy trọng yếu trong quá trình sản xuất và chế biến thực phẩm”.            
                        </p>
                        <h2 className="h2-title-page col-12 text-justify">| Chúng tôi luôn nỗ lực không ngừng để mang tới tay người tiêu dùng những sản phẩm tốt nhất với chất lượng cao nhất</h2>
                        <img src={require('../../lib/img/clean-system-end.jpg')} className="img-fluid mx-auto d-block" alt="Clean System Ginssen"/>
                    </div>
                </div>
                
                <Contact/>

                <Footer/>
            </div>
        );
    }
}

export default CleanSystemGinssen;