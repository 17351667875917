import React, { Component } from 'react';
import i18n from "i18next";
import { withNamespaces  } from 'react-i18next';

class Exports extends Component {
    componentWillMount() {
        i18n.addResourceBundle('vn', 'content-export', {
            key: '<h3>Xuất khẩu ra nước ngoài</h3><p>Daedong Korea Ginseng Co., Ltd hiện là doanh nghiệp dẫn đầu toàn cầu về xuất khẩu các dòng sản phẩm từ Nhân sâm Hàn quốc như Sâm tươi, Hồng sâm, Thái cực sâm, Hắc sâm và các dòng sản phẩm kết hợp dựa trên nguyên liệu thảo dược bổ dưỡng khác</p><h3>| Các Quốc gia xuất khẩu hiện nay |</h3><p>Trung Quốc, Nhật Bản, Thổ Nhĩ Kỳ, UAE, Hồng Kông, Mỹ, Việt Nam, Philippines, Úc</p><h3>| Các Quốc gia dự kiến xuất khẩu |</h3><p>Vương quốc Anh, Thụy Sĩ, Đức, Áo, Ấn Độ, Canada, Brazil</p>',
        });
        i18n.addResourceBundle('en', 'content-export', {
            key: '<h3>Overseas Exports</h3><p>Daedong Korea Ginseng Co., Ltd. has been exporting whole ginseng products and processed ginseng products, such as black ginseng, taegeuk ginseng, red ginseng, ginseng for several years, and is leading in the globalization of red ginseng.</p><h3>| Current exporting countries |</h3><p>China, Japan, Turkey, UAE, HK, the USA, Vietnam, the Philippines, and Australia</p><h3>| Planned export destinations |</h3><p>the UK, Switzerland, Germany, Austria, India, Canada, and Brazil</p>',
        });
    }
    render() {
        const currentHeight = (window.innerWidth >  767) ? window.innerHeight+'px' : 'auto';
        return (
            <div>
                <div id="section-6" className="section text-center cl-export d-flex align-items-center" style={{height: currentHeight, backgroundColor: '#DCC7B4' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12" dangerouslySetInnerHTML={{__html: i18n.t('content-export:key')}}/>                                                        
                        </div>
                    </div>
                </div>
                <div id="section-7" className="section d-flex align-items-center justify-content-center" style={{height: currentHeight, backgroundColor: "#DCC7B4" }}>
                    <img className="img-fluid" alt="ddk-global-map-eng" src={require('../../lib/img/ddk-global-map-eng.png')} style={{ width: "75%" }} />
                </div>
            </div>
        );
    }
}

export default withNamespaces()(Exports);